.title {
  border-radius: 10px 10px 0 0;
  background: hsl(206, 60%, 30%);
  color: white;
  font-size: 18px;
}

.updateProfile {
  background: #103c5e;
  border: 1px solid transparent;
  color: white;
  border-radius: 4px;
  padding: 3px 10px;
  transition: 1s;
  &:hover {
    background: transparent;
    border: 1px solid #103c5e !important;
    color: #103c5e;
  }
}

.profile-section {
  height: calc(100vh - 121px);
  overflow-y: auto;
  overflow-x: hidden;
}

.profileSection {
  width: 100%;
  height: calc(100vh - 112px);
  margin: 4px 0;
  img{
    width: 50px;
    height: 50px;
    margin: 4px 0;
    border: 1px solid;
  }
}

.img-account-profile {
  height: 10rem;
}
.rounded-circle {
  border-radius: 50% !important;
}

.card .card-header {
  font-weight: 500;
}
.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.card-header {
  margin-bottom: 0;
  background-color: rgb(31 83 122);
  border: 1px solid rgb(31 83 122);
  color: #fff;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (max-width: 991px) {
  .updateProfile {
    width: 70%;
  }
}

@media screen and (max-width: 500px) {
  .updateProfile {
    width: 90%;
  }
}

.Profile_img label {
  font-weight: 700;
  margin-bottom: 5px;
}

.avatar-upload {
  position: relative;
  max-width: 190px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 50%;
  background: var(--bg-100);
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: var(--bg-300);
  border-color: var(--bg-300);
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--text-100);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 155px;
  position: relative;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview img {
  border-radius: 50%;
  object-fit: cover;
  height: 155px;
}
.tabList {
  overflow: hidden;
  white-space: nowrap;

  .react-tabs__tab--selected {
    border-bottom: 2px solid var(--blue) !important;
    margin-bottom: 1px;
    .tophead,
    svg {
      color: var(--blue);
      fill: var(--blue);
      font-weight: bold;
    }
  }

  .react-tabs__tab {
    margin-right: 15px;

    &:hover {
      .tophead,
      svg {
        color: var(--blue);
        fill: var(--blue);
      }
    }
    
    &:focus-visible {
      background: var(--blue);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      border: 1px solid transparent !important;
      .tophead,
      svg {
        color: white;
        fill: white;
      }
    }

    .tophead {
      border: 1px solid transparent;
    }
  }
}

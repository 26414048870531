.footer {
  width: 100%;
  color: rgba(255, 255, 255, 0.9);
  background-color: var(--blue);
  border-bottom-color: rgba(255, 255, 255, 0.1);

  .innerFooter {
    width: 100%;
    padding: 6px 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }

  p {
    padding: 0;
    margin: 0;
  }
}

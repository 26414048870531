@import "./ReactTabs.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--blue);
  box-shadow: none;
}

.form-label,
.form-control,
.form-select,
button {
  font-size: 12px;
  border-radius: 3px;
}

.fs-10{
  font-size: 10px;
}

.h4 {
  font-size: 15px;
}

textarea:disabled {
  cursor: no-drop;
  background-color: #efefef !important;
}

#app {
  height: calc(100vh - 104.5px);
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
}

:root {
  --blue: rgb(16, 60, 94);
  --approved_color: rgb(133, 186, 133);
  --unapproved_color: rgb(196, 196, 196);
  --red_color: rgb(255, 0, 0);
  --inactive_menu_color: rgb(67, 67, 67);
  overflow: hidden;

  /* Inactive Menu Color */
  --base: #5e5690;
  --base-light: #7873a7;
  --base-dark: #555184;
  --shadow: #4f4c6b;
  --letter: #d8d7e5;
  --white: rgb(255, 255, 255);
  --border_color: rgb(237, 237, 237);
}

.alert {
  padding: 3px 8px !important;
  font-size: 10px;
  border-radius: 2px !important;
  margin: 0 !important;
}

.removeCaptionBorder {
  width: 170px;

  .blockquote-footer::before {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.white {
  color: var(--white) !important;
}

.bg-main {
  background: var(--blue) !important;
  border: 1px solid var(--blue) !important;
}

.white-background {
  background: var(--white) !important;
}

:disabled {
  cursor: no-drop;
  pointer-events: all !important;
}

.btn:disabled,
button:disabled {
  background: transparent !important;
  border-color: var(--blue) !important;
  color: var(--bg-secondary-subtle) !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--blue);
  outline: 0;
  box-shadow: none;
}

.normalHeight {
  max-height: calc(100vh - 180px);

  table {
    .form-select {
      border-radius: 3px;
      border: 0;
      padding: 1px 7px !important;
      cursor: pointer;
    }
  }
}

.modal-header,
.offcanvas-header {
  padding: 10px;
  background: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 3px 3px 0 0;
  width: 100%;

  .modal-title,
  .offcanvas-title {
    font-size: 15px;
    color: #fff;
  }

  .btn-close {
    --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  }
}

.offcanvas-header {
  border-radius: 0 !important;
}

.fs11 {
  font-size: 11px !important;
}

.blue {
  color: var(--blue);
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.gray {
  color: rgb(138, 138, 138);
}

.fontSize12 {
  font-size: 12px;
}

.fontSize14 {
  font-size: 14px;
}

.bg-blue {
  background: var(--blue);
}

.bg-red {
  background: red !important;
}

.gray {
  color: gray;
}

.green {
  color: green;
}

.dashed {
  opacity: 0.7;
}

/* .................................................................. */

table.sortable td,
table.sortable th {
  padding: 0.125em 0.25em;
  width: 8em;
}

table.sortable th {
  font-weight: bold;
  border-bottom: thin solid #888;
  position: relative;
}

table.sortable th.no-sort {
  padding-top: 0.35em;
}

table.sortable th:nth-child(5) {
  width: 10em;
}

table.sortable th button {
  position: absolute;
  padding: 4px;
  margin: 1px;
  font-size: 100%;
  font-weight: bold;
  background: transparent;
  border: none;
  display: inline;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  text-align: left;
  outline: none;
  cursor: pointer;
}

table.sortable th button span {
  position: absolute;
  right: 4px;
}

table.sortable th[aria-sort="descending"] span::after {
  content: "▼";
  color: currentcolor;
  font-size: 100%;
  top: 0;
}

table.sortable th[aria-sort="ascending"] span::after {
  content: "▲";
  color: currentcolor;
  font-size: 100%;
  top: 0;
}

table.show-unsorted-icon th:not([aria-sort]) button span::after {
  content: "♢";
  color: currentcolor;
  font-size: 100%;
  position: relative;
  top: -3px;
  left: -4px;
}

table.sortable td.num {
  text-align: right;
}

table.sortable tbody tr:nth-child(odd) {
  background-color: #ddd;
}

/* Focus and hover styling */

table.sortable th button:focus,
table.sortable th button:hover {
  padding: 2px;
  border: 2px solid currentcolor;
  background-color: #e5f4ff;
}

table.sortable th button:focus span,
table.sortable th button:hover span {
  right: 2px;
}

table.sortable th:not([aria-sort]) button:focus span::after,
table.sortable th:not([aria-sort]) button:hover span::after {
  content: "▼";
  color: currentcolor;
  font-size: 100%;
  top: 0;
}

.table td,
.table th {
  border: 1px solid var(--bs-border-color);
}

.table tr:first-child td,
table tr th {
  border-top: none !important;
}

.table tr:last-child td {
  border-bottom: 1px solid var(--bs-border-color);
}

.table tr td:first-child,
.table tr th {
  border-left: none;
}

.table tr td:last-child {
  border-right: none;
}

/* ........................................... */

.gb_Te {
  height: 28px;
  width: 35px;
}

.v {
  cursor: pointer;
  opacity: 0.4;
}

.download {
  margin-top: 5px;
}

.one100 {
  height: 20px;
  padding: 0px;
  margin-top: 3px;
  min-width: 90px;
  padding-right: 15px;
  text-align: center;
}

.arrowicon {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

.userspan {
  background: transparent;
  border: none;
  height: 20px;
}

.login {
  font-size: 0.8rem;
}

.signout {
  font-size: 0.8rem;
  margin-left: -19px;
}

.mr-nav {
  font-size: 1.5rem;
  margin-bottom: 0px;
  margin-left: 6px;
}

.rr {
  margin: 0;
  color: var(--white);
  font-size: 3rem;
}

.col-form-label {
  font-size: 0.8rem;
}

.smalls {
  font-size: small;
}

.topuseremail {
  height: 30px;
  font-size: 0.8rem;
  width: 100%;
}

.useremailid {
  width: 10%;
}

.usertopsubject {
  padding-left: 35px;
}

.clienttophr {
  margin-top: 4px;
  margin-left: -24px;
  margin-right: -24px;
}

.newclientcreation {
  overflow-x: hidden;
}

.upload {
  height: 1.8rem;
  padding-top: 2px;
}

.apps {
  display: flex;
}

.tabbars>svg {
  background: var(--bs-border-color);
  width: 25px;
  height: 25.5px;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px 3px 0 0;
  transition: 1s;
}

.tablist {
  background: var(--bs-border-color);
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}

.tabbars>svg:hover,
.tabButtons:hover,
.activee {
  background: var(--blue) !important;
  fill: #fff;
  color: #fff;
}

.tablist svg:hover {
  background: rgb(209 217 224);
  fill: var(--blue);
}

.cross {
  height: 1.3rem;
}

.refresh {
  height: 1rem;
}

.clientsicon {
  height: 1rem;
}

.mailicon {
  height: 0.8rem;
}

.usersicon {
  height: 1rem;
}

.rolesicon {
  height: 0.9rem;
}

.auditsicon {
  height: 0.8rem;
}

.avatarborder {
  border: 1px solid var(--blue);
  border-radius: 8px;
  padding: 1px;
  height: 9rem;
  width: 9rem;
}

.countryoption {
  background-color: transparent;
}

.seclect_dropdown_status {
  width: 69%;
  height: 35px;
  border: none;
  font-size: 0.8rem;
}

.form-select:focus {
  border-color: var(--blue);
  box-shadow: none;
}

.newaddress {
  height: 6rem;
}

.senthead {
  padding-bottom: 5px;
}

.editicon {
  height: 1rem;
}

.inputborder {
  border: 1px solid var(--blue);
  width: 75%;
}

.inputfonts,
.avatarleft,
.uploadlogo {
  font-size: 0.8rem;
  width: 25%;
  opacity: 0.9;
}

.uploadlogo {
  font-size: 0.8rem;
  width: 25%;
  opacity: 0.9;
}

.space {
  width: 500px;
  display: inline;
  visibility: hidden;
  background-color: red;
  margin-left: 15rem;
}

.eventtable {
  border: transparent;
}

.file1,
.file2 {
  height: 1.3rem;
  margin-left: 0px;
}

.chooselogo {
  border-radius: 10px;
  width: 75%;
}

.bottombuttons {
  margin-top: 4rem;
}

.submitbutton {
  background: #103c5e;
  border: 1px solid transparent;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  padding: 3px 10px;
  transition: 1s;

  &:hover {
    background: transparent;
    border: 1px solid #103c5e !important;
    color: #103c5e;
  }

  &:focus {
    border: 1px solid var(--blue) !important;
    background: transparent;
    color: var(--blue);
  }
}

.cancelbutton {
  background: var(--var(--white));
  border: 1px solid var(--blue);
  color: var(--blue);
  font-weight: bolder;
  min-width: 130px;
  max-width: 300px;
  padding: 4px 8px 4px 8px;

  &:hover {
    background: transparent !important;
    border: 1px solid var(--blue) !important;
    color: var(--blue);
  }

  &:focus {
    border: 1px solid var(--blue) !important;
    background: transparent;
    color: var(--blue);
  }
}

.dedicon {
  height: 1rem;
}

.newrequest1icon {
  height: 1rem;
}

.requesttypebox {
  width: 100%;
}

.reqtype,
.projectiddiv,
.studyiddiv {
  width: 33.33%;
}

.newrequestbuttons {
  width: 8rem;
}

.createroleicon {
  height: 1rem;
}

.createrolerightbottom {
  margin-top: -11px;
}

.createroleright {
  width: 33%;
  margin-left: 5px;
  height: 100vh;
}

.createrolebutton {
  background-color: var(--white);
  border: 1px solid rgb(34, 163, 255);
  width: 7rem;
}

.rolename {
  border: 1px solid var(--blue);
  width: 30%;
  height: 30px;
}

.roledescription {
  border: 1px solid var(--blue);
  width: 30%;
  height: 93px;
  border-radius: 10px;
}

.createrolefloppyicon {
  font-size: 5px;
}

.createrolefloppybutton {
  margin-top: 0px;
}

.elementana {
  background-color: hsl(102deg 39% 94%);
}

.backupleft {
  width: 53%;
}

.backuprightbottom {
  width: 46%;
  margin-left: 10px;
  height: 43rem;
}

.createroleselect {
  border: 1px solid var(--blue);
  width: 30%;
  height: 30px;
}

.createrolebuttons {
  margin-top: 5rem;
}

.roletext {
  opacity: 0.8;
  width: 13%;
}

.roleform {
  width: 100%;
}

.homecross {
  height: 0.8rem;
}

.arrowup {
  height: 1rem;
}

.arrowdown {
  height: 1rem;
}

.calender {
  height: 23px;
}

.backuprightarrow {
  height: 1rem;
}

.rightarrow {
  height: 1rem;
}

.visitrightarrow {
  height: 0.8rem;
  margin: 0px;
  padding: 0px;
}

.homebutton {
  background: url(../Components/image/newuser/homebackground.png);
  opacity: 2;
}

.mainfolder {
  height: 1rem;
}

.demographics {
  height: 1rem;
}

.selectfeild {
  height: 25px;
  width: 115px;
}

.selectfont {
  height: 24px;
  width: 101px;
  margin-top: 18px;
  padding-top: 1px;
}

.fontsize {
  width: 67px;
  height: 23px;
  padding-top: 0px;
}

.democolorselect {
  background-color: red;
  opacity: 1;
  height: 2px;
}

.selectfontlabel,
.fontsizelabel,
.fontsize,
.feildtypelabel,
.selectfeild {
  margin-top: 18px;
}

.feildtype {
  margin-bottom: -8px;
  margin-top: 2px;
}

.studysiteedit {
  height: 1rem;
}

.studysitebody {
  margin-top: 2px;
}

.mail,
.info {
  height: 1rem;
}

.trash {
  height: 1rem;
}

.childplay {
  height: 1rem;
}

.customshow {
  margin-bottom: 5px;
}

.cdashselect {
  border-radius: 5px;
  width: 54px;
  color: hsl(0deg 0% 64%);
  height: 25px;
  padding-left: 5px;
  font-size: 9px;
  text-align: center;
  border: 1px solid hsl(48deg 21% 86%);
}

.studysiteselect {
  border-radius: 5px;
  width: 54px;
  color: hsl(0deg 0% 64%);
  height: 25px;
  padding-left: 5px;
  font-size: 9px;
  text-align: center;
  border: 1px solid hsl(48deg 21% 86%);
}

.file {
  height: 1rem;
}

.sentdatafont {
  color: hsl(0deg 2% 52%);
}

.tborder {
  border: 1px solid var(--blue);
  border-radius: 10px;
}

.check {
  height: 12px;
}

.ddob {
  color: hsl(0deg 0% 39%);
}

.ddobs {
  color: hsl(0deg 0% 35%);
}

.pagelink {
  background-color: hsl(205deg 100% 38%);
  margin-right: 4px;
  margin-left: 4px;
  border-radius: 6px;
  margin-top: 1px;
  width: 100px;
  height: 1.5rem;
  color: var(--white);
  font-size: 13px;
  border: none;
  text-align: center;
}

.rightangle {
  height: 1.4rem;
  margin-top: 2px;
  border: none;
}

.rntable {
  padding-bottom: 20rem;
}

.rightpage {
  height: 1.5rem;
}

.showentries {
  font-size: 13px;
  padding-top: 3px;
}

.showentriesselect {
  padding-top: 2px;
}

.transferright {
  width: 33%;
  height: 100vh;
  border-left: 1px solid #e5e5e5 !important;
  margin-left: 5px;
}

.transfereye {
  background: transparent;
  border: none;
  padding: 0px;
}

.eyeicon {
  height: 10px;
}

@media screen and (max-width: 761px) {
  .transferright {
    border-left: none;
    margin-left: 0px;
  }
}

/* DATA TRANSFER JOB .................................. */

.datatransferjob {
  margin-top: 3px;
}

// @media screen and (max-width: 1709px) {
//   .transferrightbottom,
//   .datatransferrighttop {
//     width: 130%;
//   }
// }

@media screen and (max-width: 1528px) {
  //   .datatransferrighttop {
  //     width: 130%;
  //   }
  // }

  // @media screen and (max-width: 1320px) {
  //   .transferrightbottom,
  //   .datatransferrighttop {
  //     width: 150%;
  //   }
  // }

  // @media screen and (max-width: 1148px) {
  //   .transferrightbottom,
  //   .datatransferrighttop {
  //     width: 170%;
  //   }

  .datatransferleft {
    width: 130%;
  }
}

// @media screen and (max-width: 1050px) {
//   .transferrightbottom,
//   .datatransferrighttop {
//     width: 200%;
//   }

//   .datatransferleft {
//     width: 140%;
//   }
// }

@media screen and (max-width: 960px) {

  .transferrightbottom,
  .datatransferrighttop {
    width: 210%;
  }

  .datatransferleft {
    width: 150%;
  }
}

@media screen and (max-width: 877px) {
  .transferrightbottom {
    width: 250%;
  }

  .datatransferleft {
    width: 150%;
  }
}

@media screen and (max-width: 816px) {
  .datatransferleft {
    width: 170%;
  }
}

@media screen and (max-width: 761px) {

  .transferleft,
  .transferright,
  .transferrightbottom,
  .datatransferrighttop {
    width: 100%;
  }

  .datatransferrighttop {
    margin-top: 5px;
  }
}

@media screen and (max-width: 613px) {
  .transferrightbottom {
    width: 130%;
  }
}

@media screen and (max-width: 480px) {
  .datatransferleft {
    width: 210%;
  }
}

@media screen and (max-width: 476px) {
  .transferrightbottom {
    width: 150%;
  }
}

@media screen and (max-width: 476px) {
  .datatransferrighttop {
    width: 120%;
  }
}

@media screen and (max-width: 393px) {
  .datatransferleft {
    width: 270%;
  }
}

@media screen and (max-width: 366px) {
  .datatransferrighttop {
    width: 150%;
  }

  .transferrightbottom {
    width: 220%;
  }
}

/* ..................................................... */

/* STUDYDETAILS.......................................... */

.studydetail {
  width: 100%;
  margin-top: 2px;
}

@media screen and (max-width: 1239px) {
  .studydetailtable {
    width: 130%;
  }
}

@media screen and (max-width: 956px) {
  .studydetailtable {
    width: 150%;
  }
}

@media screen and (max-width: 830px) {
  .studydetailtable {
    width: 180%;
  }
}

@media screen and (max-width: 693px) {
  .studydetailtable {
    width: 210%;
  }
}

@media screen and (max-width: 596px) {
  .studydetailtable {
    width: 260%;
  }
}

@media screen and (max-width: 483px) {
  .studydetailtable {
    width: 300%;
  }
}

@media screen and (max-width: 403px) {
  .studydetailtable {
    width: 400%;
  }
}

/* ...................................................... */

/* CODELIST.............................................. */

.codelistmain {
  height: 100vh;
}

.codelistleft {
  width: 50%;
}

.codelistright {
  width: 49.6%;
  margin-left: 5px;
}

@media screen and (max-width: 1313px) {
  .codelistlefttable {
    width: 130%;
  }
}

@media screen and (max-width: 1182px) {
  .codelistrighttable {
    width: 130%;
  }
}

@media screen and (max-width: 1016px) {
  .codelistlefttable {
    width: 140%;
  }

  .codelistrighttable {
    width: 130%;
  }
}

@media screen and (max-width: 945px) {
  .codelistlefttable {
    width: 160%;
  }

  .codelistrighttable {
    width: 130%;
  }
}

@media screen and (max-width: 945px) {
  .codelistlefttable {
    width: 160%;
  }

  .codelistrighttable {
    width: 150%;
  }
}

@media screen and (max-width: 830px) {
  .codelistlefttable {
    width: 200%;
  }

  .codelistrighttable {
    width: 170%;
  }
}

@media screen and (max-width: 761px) {
  .codelistmain {
    display: flex;
    flex-direction: column;
  }

  .codelistlefttable,
  .codelistrighttable,
  .codelistright,
  .codelistleft {
    width: 100%;
  }
}

@media screen and (max-width: 667px) {
  .codelistlefttable {
    width: 130%;
  }
}

@media screen and (max-width: 597px) {
  .codelistlefttable {
    width: 130%;
  }

  .codelistrighttable {
    width: 130%;
  }
}

@media screen and (max-width: 518px) {
  .codelistlefttable {
    width: 150%;
  }

  .codelistrighttable {
    width: 130%;
  }
}

@media screen and (max-width: 464px) {
  .codelistlefttable {
    width: 150%;
  }

  .codelistrighttable {
    width: 160%;
  }
}

@media screen and (max-width: 452px) {
  .codelistlefttable {
    width: 180%;
  }

  .codelistrighttable {
    width: 160%;
  }
}

@media screen and (max-width: 381px) {
  .codelistlefttable {
    width: 210%;
  }

  .codelistrighttable {
    width: 200%;
  }
}

/* ...................................................... */

/* VALUELEVEL............................................ */

.valuelevelleft {
  width: 36%;
}

.valuelevelright {
  width: 63.6%;
  margin-left: 5px;
}

.valuelevelrighttable {
  width: 130%;
}

@media screen and (max-width: 1533px) {
  .valuelevellefttable {
    width: 130%;
  }
}

@media screen and (max-width: 1462px) {
  .valuelevelrighttable {
    width: 150%;
  }
}

@media screen and (max-width: 1269px) {
  .valuelevelrighttable {
    width: 170%;
  }
}

@media screen and (max-width: 1183px) {
  .valuelevellefttable {
    width: 150%;
  }
}

@media screen and (max-width: 1121px) {
  .valuelevelrighttable {
    width: 200%;
  }
}

@media screen and (max-width: 1027px) {
  .valuelevellefttable {
    width: 180%;
  }
}

@media screen and (max-width: 955px) {
  .valuelevelrighttable {
    width: 220%;
  }
}

@media screen and (max-width: 869px) {
  .valuelevelmain {
    display: flex;
    flex-direction: column;
  }

  .valuelevelleft,
  .valuelevelright,
  .valuelevellefttable {
    width: 100%;
    margin: 0px;
  }
}

@media screen and (max-width: 869px) {
  .valuelevellefttable {
    width: 130%;
  }

  .valuelevelrighttable {
    width: 250%;
  }
}

@media screen and (max-width: 491px) {
  .valuelevellefttable {
    width: 130%;
  }

  .valuelevelrighttable {
    width: 300%;
  }
}

@media screen and (max-width: 433px) {
  .valuelevellefttable {
    width: 150%;
  }

  .valuelevelrighttable {
    width: 320%;
  }
}

@media screen and (max-width: 387px) {
  .valuelevellefttable {
    width: 150%;
  }

  .valuelevelrighttable {
    width: 400%;
  }
}

@media screen and (max-width: 377px) {
  .valuelevellefttable {
    width: 180%;
  }

  .valuelevelrighttable {
    width: 400%;
  }
}

/* ...................................................... */

.settingright {
  width: 58%;
  margin-left: 5px;
  height: 100vh;
}

.demographic2main {
  width: 100%;
  overflow-x: hidden;
}

.demographic2bg {
  width: 100%;
  height: 30vh;
}

.demo2left {
  width: 20%;
}

.demo2genderbutton {
  border-radius: 0px;
}

.demo2genderflex {
  display: flex;
  flex-direction: column;
  height: 60vh;
}

.menuclose {
  background: transparent;
  border: none;
}

.demographic2genderselect {
  background-color: hsl(0deg 0% 94%);
  height: 30vh;
}

.demo2gender {
  width: 20%;
}

.demographic2column {
  // display: flex;
  // flex-direction: column;
  // width: 79.2%;
  // margin-left: 12px;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  width: 79.6%;
  margin-left: 5px;
  height: 100vh;
}

.settingtablebg {
  background-color: #eaf1f6;
}

.demographicstablebg,
.settingbg {
  background-color: rgb(207, 221, 252);
}

.visitbg {
  background-color: #eaf1f6;
}

.visitfont {
  color: hsl(220deg 94% 31%);
  font-weight: lighter;
}

.visitdatafont {
  color: hsl(0deg 0% 37%);
}

.visitdatabg {
  background-color: hsl(0deg 0% 95%);
}

.visitright {
  width: 49.8%;
  margin-left: 2px;
  height: 44rem;
}

.eventsearch {
  border: 1px solid hsl(196deg 100% 47%);
  border-radius: 5px;
  height: 1.7rem;
  width: 130px;
}

.sentrequesttable {
  // margin-top: 2px;
  margin-left: 0px;
  margin-right: 0px;
}

.scheduleleft {
  width: 66.5%;
  margin-right: 2px;
  height: 40rem;
}

.scheduleright {
  height: 40rem;
}

.schedulesecond {
  height: 13rem;
}

.schedulethird {
  height: 15rem;
}

.sno {
  border: 1px solid rgb(1, 70, 119);
  border-radius: 10px;
  margin-top: 7px;
  height: 37px;
  padding-top: 5px;
  width: 98.4%;
  margin-left: auto;
  margin-right: auto;
}

.asiancheck {
  width: 17px;
  height: 17px;
  color: blue;
}

.birthcheck {
  width: 17px;
  height: 14px;
}

.sn {
  padding-left: 5px;
  width: 25%;
}

.dateofbirth {
  margin-left: 25rem;
}

.datepick {
  margin-right: 3rem;
}

.year {
  margin-left: 2rem;
}

.month {
  margin-left: 2rem;
}

.date,
.month,
.year {
  width: 5rem;
  height: 2rem;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border: 1px solid var(--blue);
}

.racecheck {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: solid #ff0000 1px;
}

.race2 {
  margin-right: 2.7rem;
}

.asian {
  margin-right: 14px;
}

.alaska {
  margin-left: 36.5rem;
}

.datat1 {
  margin-left: 3.5rem;
}

.demographicsdatatype {
  width: 25%;
  text-align: center;
}

.demographiccdashvariable {
  width: 25%;
}

.demographicsunit {
  width: 25%;
}

.scheduleleft {
  overflow: auto;
}

.labdatabg {
  background-color: #e2f0d9;
}

.labdatabg1 {
  background-color: #eaf1f6;
}

.labtableleft {
  width: 60%;
  height: 45rem;
  overflow: auto;
}

.customlefttable {
  height: 42rem;
}

.customrighttable {
  height: 42rem;
  width: 33.1%;
  margin-left: 3px;
}

.labrighttbbg {
  background-color: #f2f2f2;
}

.labtableright {
  width: 39.6%;
  height: 45rem;
  overflow: auto;
  margin-left: 5px;
}

.dataentryleft {
  height: 100vh;
}

.dataentrytab {
  background-color: hsl(48deg 21% 86%);
}

.dataentryright {
  height: 100vh;
}

.demographicstablefont,
.tophead {
  color: gray;
  font-weight: lighter;
  font-size: 13px;
}

.settinginput {
  height: 11px;
}

.table-bordereds {
  border: 1px solid var(--white);
}

.leftdiv {
  width: 65%;
  height: 100vh;
}

.userright {
  width: 35%;
  height: 100vh;
}

.methodbuttons {
  border: 1px solid #cfd3d6;
  width: 10rem;
  height: 2.1rem;
  background-color: var(--white);
  margin-bottom: 4px;
}

.middlemethod {
  background-color: var(--white);
  border-radius: 10px;
  width: 93%;
  margin: auto;
  padding: 17px;
}

.tbackground {
  height: 80vh;
  background-color: hsl(48deg 21% 86%);
}

.notemessage {
  background-color: var(--white);
  border-radius: 10px;
  width: 93%;
  margin: auto;
  padding: 17px;
}

.addlibrary {
  width: 100%;
  margin-bottom: 59px;
}

.endbtn {
  padding-right: 50px;
}

.searchbar {
  height: 2rem;
}

.ok,
.clock {
  height: 1rem;
}

.border-none {
  border: none;
  background-color: var(--white);
}

.studyscheduleedit,
.edit {
  height: 1rem;
}

.print {
  height: 1rem;
}

.mainfile {
  height: 1rem;
}

.navprofile {
  height: 22px;
}

.profiler {
  margin-bottom: -20px;
  padding-top: 3px;
  margin-right: 5px;
}

.profiler1 {
  margin-bottom: -20px;
  padding-top: 3px;
  margin-right: -3px;
}

@media screen and (max-device-width: 575px) {
  .profiler1 {
    display: none;
  }
}

.client1icon,
.projectidicon {
  height: 1rem;
}

.client1,
.projectid,
.studyid {
  font-size: 12px;
  margin-right: 5px;
  margin-left: 3px;
}

.container-fluids {
  width: 98%;
  margin-left: 5px;
}

.homeicon {
  background: url(../Components/image/homebackground.png);
  background-size: 25px;
}

.usbjid {
  font-weight: lighter;
}

.m11 {
  background-color: hsl(97deg 40% 90%);
  line-height: 1.6;
}

.m11 a {
  text-decoration: none;
}

.margin {
  width: 99%;
  height: 100vh;
}

.sides {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.methodsidebg {
  background-color: hsl(0deg 0% 95%);
}

.methodsidefont {
  color: hsl(0deg 0% 0%);
}

@media screen and (max-device-width: 1600px) {
  .tbackground {
    height: 116vh;
  }
}

@media screen and (max-device-width: 1376px) {
  .tbackground {
    height: 120vh;
  }
}

@media screen and (max-device-width: 1127px) {
  .tbackground {
    height: 140vh;
  }
}

@media screen and (max-device-width: 844px) {
  .tbackground {
    height: 150vh;
  }
}

/* ............................................................. */

.btn-blue {
  background: var(--blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 100%;
  color: var(--white);
  margin: 0px;
  padding: 2px;
  border: 1px solid var(--blue) !important;

  &:hover {
    background: #134971;
    color: var(--white) !important;
    border: 1px solid var(--blue) !important;
  }

  &:focus {
    background: #134971;
    color: var(--white) !important;
    border: 1px solid var(--blue) !important;
  }
}

.border-blue {
  border-color: var(--blue) !important;
  border-width: 3px !important;
}

.border-info {
  border: 1px solid var(--blue) !important;
}

.rotate-up {
  transform: rotate(270deg);
}

.rotate-down {
  transform: rotate(90deg);
}

.activeclient {
  color: rgb(80, 193, 80) !important;
}

.disableColor {
  color: #afa7a7 !important;
}

.archievedusers {
  color: gray;
}

.frozenusers {
  color: #ddaf00;
}

.onBoardusers {
  color: blue;
}

.pendingclient {
  color: rgb(146, 113, 50);
}

.textCapitialize {
  text-transform: capitalize;
}

.accessManage {
  color: var(--blue);
}

.inactiveclient {
  color: hsl(359deg 78% 61%) !important;
}

.awaiting {
  color: hsl(200deg 94% 23%);
}

.ongoing {
  color: rgb(110, 183, 0);
}

.locked {
  color: hsl(149deg 95% 30%);
}

.completed {
  color: hsl(198deg 33% 52%);
}

.inactive {
  color: hsl(4deg 80% 66%);
}

.topbox {
  display: flex;
}

.clients,
.request,
.users {
  color: var(--blue);
}

.roles,
.audits {
  color: var(--blue);
}

.navcolap {
  padding-bottom: 13px;
}

.auditcolap {
  padding-left: 8px;
}

.rolescolap {
  padding-left: 25px;
}

.auditscolap {
  padding-left: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: black;
  background-color: hsl(48deg 21% 86%);
}

.genderselect {
  border-left: 1px solid #e5e5e5;
  height: 35rem;
  width: 99.6%;
  margin-left: 5px;
}

.genderpane {
  display: flex;
}

.leftbox {
  width: 24.6%;
  border-right: 1px solid #e5e5e5;
}

.leftbox1 {
  margin-top: 16px;
}

.htarea {
  width: 98%;
  border-radius: 10px;
}

.testinput {
  width: 45rem;
}

.testhead {
  background-color: hsl(205deg 39% 87%);
}

.testinput1 {
  height: 38px;
  margin-top: 4px;
  border-radius: 10px;
}

.testtablediv {
  width: 100%;
}

.testt1r {
  color: red;
}

.testt2r {
  color: green;
}

.testbtn {
  background-color: var(--white);
  width: 9rem;
  height: 35px;
  margin-top: 5px;
}

.testdatarow {
  background-color: hsl(202deg 40% 96%);
}

.models {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 68%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  outline: 0;
  height: 100%;
  min-height: 164px;
  background-color: hsl(24deg 84% 83%);
  padding-bottom: 1rem;
}

.modalcross {
  background-color: transparent;
  border: none;
  color: hsl(202deg 56% 53%);
}

.modals-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  line-height: 1.6;
  font-size: 16px;
}

.modaltxt {
  color: hsl(30deg 17% 5%);
}

.historybg {
  background-color: #d1e0eb;
}

.historydatabg,
.historyheight {
  background-color: var(--white);
}

.hheighttable {
  height: 100vh;
}

.historytxt {
  color: #262626;
  font-weight: lighter;
}

.hrcolor {
  color: rgb(158, 162, 162);
  margin-bottom: 5px;
}

.testrule {
  margin-bottom: 6px;
  padding-top: 5px;
}

.testval {
  margin-top: 5px;
  margin-bottom: 5px;
}

.testsection {
  width: 97%;
  margin: auto;
}

.add {
  background-color: var(--white);
  width: 9rem;
  padding: 5px;
  border-radius: 8px;
  font-size: 14px;
}

.nav-link {
  color: #4c4c4c;
}

.page-link {
  color: var(--blue);
}

.navcard {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.navroles {
  background: transparent;
  border: none;
  color: var(--white) fff;
  text-decoration: none;
}

.drop-down02 {
  position: relative;
}

.drop-down02 .sub-menu02 {
  position: absolute !important;
  left: 100%;
  top: 0;
}

.drop-down02 .dropdown-toggle {
  padding: 0.25rem 1.1rem !important;
}

#sub-dropdown-menu {
  display: none;
  position: absolute;
  left: 260px;
  top: 10px;
}

.studyschedulehover:hover #sub-dropdown-menu {
  display: block;
}

.homeicon {
  font-family: "Font Awesome 5 Pro";
}

@media screen and (max-width: 767px) {
  .drop-down02 .sub-menu02 {
    position: static !important;
  }
}

/* SCROLLBAR........................................... */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.blank::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--blue);
  // border-radius: px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}

.dropdown {
  background: transparent;
  border: none;
  padding-left: 0px;
}

.dropdowns {
  color: transparent;
}

.show>.btn-primary.dropdown-toggle {
  background-color: transparent;
  // border-color: transparent !important;
}

.dropdown:hover {
  background: transparent;
  border: none;
  border-color: transparent;
}

.searchselector {
  background-color: var(--white);
  border: none;
  border-radius: 5px;
  width: 17px;
}

.searchmagnifier {
  margin-top: 7px;
  font-size: 12px;
  font-weight: bolder;
  color: black;
}

.navsearch {
  height: 24px;
  border: none;
  padding-left: 1px;
}

.navdropdown {
  margin-left: -24px;
}

.searchselectoption {
  color: black;
  background-color: var(--white);
}

.gaurav {
  margin-right: 9px;
  max-width: 23rem;
}

.adminLoggedIn {
  max-width: 20rem;
  margin-right: 20px;
}

.galaxbar {
  height: 34px;
}

// .galaxhr {
//   margin: 0px;
//   opacity: 0.2;
// }

// .filebar {
//   margin-bottom: -5px;
// }

/* End Here */

/* Middle Content */

section.middle-content {
  width: 100%;

  .sidebar {
    width: 100%;
    height: calc(100vh - 115px);
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    overflow: hidden;

    .menus {
      ul {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        justify-content: flex-start;

        li {
          display: flex;
          padding: 5px;
          justify-content: space-between;
        }
      }
    }
  }

  .sidebar-body {
    overflow: auto;
    max-height: calc(100vh - 147px);
    min-width: 250px;
  }

  .inner-cont {
    height: calc(100vh - 115px);
    // border: 1px solid #e5e5e5;
    border-radius: 3px;
    scrollbar-width: none;
    overflow: hidden;
  }
}

// section.middle-content .sidebar {
//   width: 100%;
//   // height: calc(100vh - 110px);
//   border: 1px solid #e5e5e5;
//   border-radius: 3px;
//   // overflow: auto;
//   overflow: hidden;
// }

// section.middle-content .sidebar-body {
//   overflow: auto;
//   // height: calc(100vh - 155px);
// }

// section.middle-content .inner-cont {
//   width: 100%;
//   height: calc(100vh - 115px);
//   border: 1px solid #e5e5e5;
//   border-radius: 3px;
//   scrollbar-width: none;
//   overflow: hidden;
// }

section.middle-content .sidebar .menus ul li .hr-line {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
}

section.middle-content .sidebar .menus ul li a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 13px;
  text-wrap: nowrap;

  &:hover {
    text-decoration: none;
  }
}

section.middle-content .inner-cont .home-line {
  width: 100%;
  padding: 0px;
  height: 31px;

  .slideRight {
    margin-left: auto;
  }
}

section.middle-content .inner-cont .home-line i {
  font-size: 0.9rem;
}

section.middle-content .inner-cont .home-line2 {
  width: 100%;
  padding: 0px;
}

section.middle-content .inner-cont .home-line2 i {
  font-size: 1rem;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
}

a[aria-expanded="true"] .fa-angle-right {
  transform: rotate(90deg);
  padding-right: 10px;
}

a[aria-expanded="false"] .fa-chevron-down {
  display: none;
}

.plane {
  color: black;
}

.dedhome-line {
  width: 100%;
  padding: 10px;
}

.home-line {
  width: 100%;
  padding: 10px;
}

.grid {
  padding-left: 2px;
}

.dashboard-body {
  max-height: calc(100vh - 170px);
  width: 100%;
  overflow: auto;
}

.dashboard-body .dashboard_item {
  height: 100%;
}

.hovertablerow :hover {
  background-color: rgb(184, 184, 186);
}

.dashboard_item .list-group {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 11px;
  background: aliceblue;
  height: 100%;
}

.listgroup {
  margin-bottom: 22px;
}

.sov {
  width: 100.5%;
}

.homedash {
  height: calc(100vh - 37px);
}

@media screen and (max-device-width: 565px) {
  section.middle-content .sidebar .menus ul li {
    padding: 2px;
  }
}

@media screen and (max-device-width: 398px) {
  .vr {
    visibility: hidden;
  }
}

/* DASHBOARD RESPONSIVENESS............................ */

@media screen and (max-device-width: 1401px) {
  .list-group {
    margin-bottom: 23px;
  }

  .sov {
    width: 100.8%;
  }

  .homedash {
    height: 91%;
  }
}

@media screen and (max-device-width: 1380px) {
  .list-group {
    margin-bottom: 22px;
  }

  .homedash {
    height: 91%;
  }
}

@media screen and (max-device-width: 1339px) {
  .list-group {
    margin-bottom: 43px;
  }
}

@media screen and (max-device-width: 1205px) {
  .list-group {
    margin-bottom: 43px;
  }
}

@media screen and (max-device-width: 1176px) {
  .list-group {
    margin-bottom: 31px;
  }
}

@media screen and (max-device-width: 1140px) {
  .list-group {
    margin-bottom: 31px;
  }
}

@media screen and (max-device-width: 1132px) {
  .list-group {
    margin-bottom: 31px;
  }
}

@media screen and (max-device-width: 1110px) {
  .list-group {
    margin-bottom: -11px;
  }
}

@media screen and (max-device-width: 1090px) {
  .topbox {
    display: flex;
    flex-wrap: wrap;
  }

  .fbox {
    width: 99.5%;
  }

  .sbox {
    width: 100%;
    margin-top: 5px;
  }

  .tbox,
  .tbox1 {
    width: 100%;
  }

  .sov {
    width: 100%;
  }
}

@media screen and (max-device-width: 622px) {
  .fboxtab {
    display: flex;
    flex-direction: column;
  }

  .fbox622 {
    width: 100%;
    margin-bottom: 15px;
  }

  .sboxtab {
    display: flex;
    flex-direction: column;
  }
}

.dashboard_item .list-group .list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent;
}

.dashboard_item .list-group .list-group-item span i {
  margin-right: 10px;
}

section.middle-content .client-collapse .card-body {
  border: none;
  padding: 0;
}

section.middle-content .client-collapse .card-body a {
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  text-wrap: nowrap;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e5e5e5;
}

section.middle-content .collapse:not(.show) {
  display: none;
}

/* End Here */

/* user form css */

.dashboard-body .form-section .form-button {
  text-align: end;
}

.dashboard-body .form-section .img-thumbnail {
  padding: 0.25rem;
  background-color: var(--white);
  border: 1px solid #0dcaf0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: 100px;
}

.fa-eye {
  font-size: 10px;
}

/* user form css end */

.updateProfiles_css {
  background: #103c5e;
  border: 1px solid transparent;
  color: var(--white);
  border-radius: 10px;
  font-weight: bold;

  &:hover {
    background: transparent;
    border: 1px solid #103c5e !important;
    color: #103c5e;
  }
}

/* Custom-nav-css */

.nav-tabs .nav-link.active {
  color: var(--blue);
  background-color: var(--white);
  border-bottom: 2px solid;
  border-color: var(--white) fff var(--white) fff var(--blue);
  border-radius: 10px 0 0 0;
  font-weight: bold;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--white) fff var(--white) fff var(--blue);
  isolation: isolate;
  color: var(--blue);
}

/* login-form-css */

.login-form .form-check-label {
  font-size: 20px;
}

/* expand css */

.demomain {
  overflow-x: hidden;
}

.Resizer.horizontal {
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1px solid var(--bs-border-color);
  height: 4px;
  cursor: s-resize;
  transition: 0.2s;
}

.Resizer.vertical {
  border-left: 1px solid var(--bs-border-color);
  border-right: 1px solid var(--bs-border-color);
  width: 4px;
  cursor: e-resize;
  transition: 0.2s;
}

.Resizer.horizontal:hover,
.Resizer.vertical:hover {
  background: var(--bs-border-color);
}

.Pane.horizontal.Pane2 {
  overflow: hidden;
  padding-bottom: 25px;
}

.Pane {
  overflow: auto;
}

.Pane.vertical {
  padding: 0 0px;
}

.Pane.horizontal {
  display: block !important;
}

.resizable-x,
.resizable-y {
  display: flex;
}

.resizable-x {
  height: 100%;
  padding: 5px;
}

.resizable-y {
  flex-direction: column;
  width: -webkit-fill-available;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  overflow-y: hidden;
}

.resizer-x,
.resizer-y {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.resizer-x {
  z-index: 2;
  cursor: col-resize;
  width: 5px;
}

.resizer-x::before,
.resizer-x::after {
  content: "";
  width: 1px;
  height: 16px;
  margin: 1px;
  background: rgb(115, 115, 115);
}

.resizer-xHidden {
  z-index: 2;
  opacity: 0 !important;
  cursor: none !important;
  width: 5px;
}

.resizer-y {
  z-index: 1;
  cursor: col-resize;
  flex-direction: column;
}

.resizer-y::before,
.resizer-y::after {
  content: "";
  width: 16px;
  height: 2px;
  margin: 2px;
  background: var(--white);
}

.div0 {
  width: 250px;
}

.resizable-y {
  min-width: 50%;
}

.eventleft {
  width: 27%;
  height: 100vh;
  margin-top: 3px;
}

.eventfilter {
  background-color: transparent;
}

.eventright {
  height: 100vh;
  margin-top: 3px;
}

.question {
  height: 1.5rem;
}

// .resizable-y {
//   flex: 40%;
// }

#client-section,
#new-request-section,
#sent-request-section,
#new-user-section,
#demographic-section,
#demographic-2-section,
#study-schedule-section,
#study-randomization-section,
#study-site-section,
#visit-crf-martix-section,
#data-transfer-job-section {
  display: none;
}

/* end here */

/* login-form-css */

.login-form .form-check-label {
  font-size: 20px;
}

.newuserleft,
.newuserright {
  width: 50%;
}

/* NEW REQUEST......................................... */

.useremailmain {
  width: 100%;
}

.useremail {
  width: 7%;
}

.newreqemailid {
  width: 93%;
  height: 31px;
  border: 1px solid var(--blue);
}

.useremail,
.subject {
  opacity: 0.7;
  font-size: 0.8rem;
}

.subjectrequestmain {
  width: 100%;
}

.subject {
  width: 4.5%;
}

.subjectinput {
  width: 95.5%;
  height: 31px;
  border: 1px solid var(--blue);
}

.schedulemain {
  width: 99.9%;
}

.requesttypelabel {
  width: 34%;
}

.selectrequesttype {
  border: 1px solid var(--blue);
  width: 66%;
  height: 2.3rem;
}

.expected {
  width: 9rem;
}

.attach {
  width: 307px;
  height: 55px;
}

.attachleft {
  width: 90%;
  height: 100%;
  background-color: hsl(203deg 69% 88%);
}

.attachhr {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: -8px;
  margin-right: -8px;
}

.newrequestbg {
  background-color: hsl(205deg 40% 94%);
  margin: 0px;
  padding-top: 15px;
  height: 100vh;
}

.projectidlabel {
  width: 25%;
}

.projectidinput {
  border: 1px solid var(--blue);
  width: 75%;
  height: 2.3rem;
}

.studyidlabel {
  width: 25%;
}

.studyidinput {
  width: 75%;
  border: 1px solid var(--blue);
  height: 2.3rem;
}

.clientlabel {
  width: 11.4%;
}

.clientname {
  height: 2.3rem;
  border-radius: 5px;
  border: 1px solid var(--blue);
  width: 89.3%;
  padding-left: 15px;
}

.clientnamediv {
  width: 100%;
}

.protocolversionlabel {
  width: 23%;
}

.protocol,
.enrollment {
  width: 50%;
}

.protocolversioninput {
  height: 2.3rem;
  border-radius: 5px;
  border: 1px solid var(--blue);
  width: 44%;
}

.expectedenrollmentlabel {
  width: 40%;
  padding-left: 10px;
  padding-top: 4px;
}

.expectedenrollmentinput {
  height: 2.3rem;
  border-radius: 5px;
  border: 1px solid var(--blue);
  width: 60%;
}

.clientnametextarea {
  width: 100%;
  border-radius: 7px;
  border: 1px solid var(--blue);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.clientnametextareas {
  width: 90%;
  border-radius: 7px;
  border: 1px solid var(--blue);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.herapeutic {
  height: 2.3rem;
  width: 60%;
  border: 1px solid var(--blue);
}

.studyphase {
  width: 40%;
}

.studytype {
  width: 13%;
}

.studytypes {
  width: 11.3%;
}

.herapeutics {
  width: 22%;
  height: 2.3rem;
  border: 1px solid var(--blue);
}

/* DATA TRANSFER JOB CREATION ........................*/

.creationmain {
  overflow-x: hidden;
}

.dataextract-dashboard-body {
  overflow: hidden;
}

.table-responsive-creation {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.datacreationleft {
  width: 66%;
}

.datacreationleft {
  margin: 0px;
}

.datacreationright {
  height: 100vh;
  width: 33.7%;
}

.datacreationright {
  border-left: 1px solid #e5e5e5 !important;
  margin-left: 5px;
}

.datacreationleft,
.datacreationright {
  height: 100vh;
}

.creationinnerflex {
  width: 50%;
  margin-top: 10px;
}

.creationinnerflex1 {
  margin-top: 10px;
}

.creationlabel {
  width: 40%;
  padding-left: 10px;
}

.creationinput {
  border: 1px solid var(--blue);
  width: 60%;
  height: 2.3rem;
}

.dattransferid {
  width: 50%;
}

.projectidinputs {
  border: 1px solid var(--blue);
  width: 60%;
  height: 2.3rem;
}

.projectidlabels {
  width: 20%;
  padding-left: 56px;
}

.emailsingle {
  padding-left: 41px;
}

.projectidlabel1 {
  width: 25%;
}

.projectidinputs1 {
  border: 1px solid var(--blue);
  width: 80%;
  height: 2.3rem;
}

@media screen and (max-device-width: 1187px) {

  .creationinput,
  .creationlabel {
    width: 50%;
  }

  .projectidlabels {
    width: 25%;
  }

  .projectidinputs1 {
    width: 75%;
  }
}

@media screen and (max-device-width: 1053px) {
  .projectidlabel1 {
    width: 50%;
    padding-left: 4px;
  }

  .projectidinputs {
    width: 50%;
  }

  .projectidinputs1 {
    width: 75%;
  }

  .projectidlabels {
    width: 25%;
  }
}

@media screen and (max-device-width: 997px) {
  .creationtable {
    width: 130%;
  }
}

@media screen and (max-device-width: 972px) {
  .creationmain {
    display: flex;
    flex-direction: column;
  }

  .creationtable {
    width: 100%;
  }

  .datacreationright {
    border-left: none;
    margin-left: 0px;
  }

  .datacreationleft {
    height: 30vh;
    overflow: auto;
  }

  .table-responsive-creation {
    height: 37vh;
    overflow: auto;
    margin-top: 10px;
  }

  .datacreationleft,
  .datacreationright {
    width: 100%;
  }

  .creationlabel {
    width: 45%;
  }

  .creationlabel {
    width: 55%;
  }

  .projectidlabels {
    width: 26.4%;
  }

  .projectidinputs1 {
    width: 73.6%;
  }
}

@media screen and (max-device-width: 643px) {
  .creationflexmain {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .creationinnerflex {
    width: 100%;
  }

  .creationlabel,
  .projectidlabels {
    width: 35%;
    padding: 0px;
  }

  .creationinput,
  .projectidinputs1 {
    width: 65%;
  }
}

@media screen and (max-device-width: 467px) {

  .creationinnerflex,
  .creationinnerflex1 {
    display: flex;
    flex-direction: column;
  }

  .creationlabel,
  .projectidlabels,
  .creationinput,
  .projectidinputs1 {
    width: 100%;
  }
}

@media screen and (max-device-width: 336px) {
  .creationtable {
    width: 130%;
  }
}

/* ................................................... */

@media screen and (max-device-width: 1800px) {
  .useremail {
    width: 8%;
  }

  .newreqemailid {
    width: 92%;
  }

  .subject {
    width: 5.3%;
  }

  .subjectinput {
    width: 94.7%;
  }
}

@media screen and (max-device-width: 1690px) {
  .useremail {
    width: 9%;
  }

  .newreqemailid {
    width: 91%;
  }

  .subject {
    width: 6.3%;
  }

  .subjectinput {
    width: 93.7%;
  }
}

@media screen and (max-device-width: 1570px) {
  .subject {
    width: 6.1%;
  }

  .subjectinput {
    width: 93.9%;
  }
}

@media screen and (max-device-width: 1500px) {
  .useremail {
    width: 9.2%;
  }

  .newreqemailid {
    width: 90.8%;
  }

  .subject {
    width: 6.1%;
  }

  .subjectinput {
    width: 93.9%;
  }
}

@media screen and (max-device-width: 1500px) {
  .useremail {
    width: 10.3%;
  }

  .newreqemailid {
    width: 89.7%;
  }

  .subject {
    width: 7.1%;
  }

  .subjectinput {
    width: 92.9%;
  }
}

@media screen and (max-device-width: 1350px) {
  .useremail {
    width: 11.3%;
  }

  .newreqemailid {
    width: 88.7%;
  }

  .subject {
    width: 8.1%;
  }

  .subjectinput {
    width: 91.9%;
  }
}

@media screen and (max-device-width: 1143px) {
  .subject {
    width: 9.9%;
  }

  .subjectinput {
    width: 90.1%;
  }

  .useremail {
    width: 14%;
  }

  .newreqemailid {
    width: 86%;
  }
}

@media screen and (max-device-width: 1009px) {
  .subject {
    width: 10.9%;
  }

  .useremail {
    width: 15.1%;
  }
}

/* ......................................... */

/* DATA LOCK REQUEST........................... */

@media screen and (max-device-width: 1176px) {
  .projectidlabel {
    width: 35%;
  }

  .projectidinput {
    width: 65%;
  }
}

/* ............................................ */

/* SENT REQUEST........................................ */

@media screen and (min-device-width: 559px) and (max-width: 740px) {
  .sentrequesttable {
    width: 140%;
  }
}

@media screen and (max-device-width: 559px) {
  .sentrequesttable {
    width: 180%;
  }
}

@media screen and (max-device-width: 413px) {
  .sentrequesttable {
    width: 200%;
  }

  .sentdatafont {
    font-size: 12px;
  }

  .sentrequestfont {
    font-size: 12px;
  }
}

@media screen and (max-device-width: 413px) {
  .sentrequesttable {
    width: 220%;
  }
}

/* ............................................... */

@media screen and (max-width: 1284px) {
  .useremailid {
    width: 11%;
  }

  .topuseremail {
    width: 89%;
  }
}

@media screen and (max-width: 1236px) {
  .useremailid {
    width: 11%;
  }

  .topuseremail {
    width: 89%;
  }
}

@media screen and (max-width: 1236px) {
  .useremailid {
    width: 12%;
  }

  .topuseremail {
    width: 88%;
  }
}

@media screen and (max-width: 1100px) {
  .useremailid {
    width: 12%;
  }

  .topuseremail {
    width: 88%;
  }
}

@media screen and (max-width: 1084px) {
  .useremailid {
    width: 13%;
  }

  .topuseremail {
    width: 87%;
  }
}

@media screen and (max-width: 1007px) {
  .useremailid {
    width: 14%;
  }

  .topuseremail {
    width: 86%;
  }
}

@media screen and (max-width: 941px) {
  .useremailid {
    width: 16%;
  }

  .topuseremail {
    width: 84%;
  }
}

@media screen and (max-width: 883px) {
  .useremailid {
    width: 19%;
  }

  .topuseremail {
    width: 81%;
  }
}

@media screen and (max-width: 715px) {
  .useremailid {
    width: 22%;
  }

  .topuseremail {
    width: 78%;
  }
}

@media screen and (max-width: 715px) {
  .useremailid {
    width: 21%;
  }

  .topuseremail {
    width: 79%;
  }
}

@media screen and (max-width: 715px) {
  .useremailid {
    width: 22%;
  }

  .topuseremail {
    width: 78%;
  }
}

@media screen and (max-width: 628px) {
  .useremailid {
    width: 23%;
  }

  .topuseremail {
    width: 77%;
  }
}

@media screen and (max-width: 605px) {
  .useremailid {
    width: 26%;
  }

  .topuseremail {
    width: 74%;
  }
}

@media screen and (max-width: 540px) {
  .usertopbox {
    display: flex;
    flex-direction: column;
  }

  .useremailid {
    width: 100%;
    margin-bottom: 10px;
    padding: 0px;
  }

  .topuseremail {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {

  .firstname,
  .middlename,
  .lastname,
  .address,
  .postalcode,
  .state,
  .userid,
  .city,
  .country,
  .mobilenumber,
  .officephone,
  .emailid,
  .designation {
    display: flex;
  }

  .inputborder {
    width: 100%;
  }

  .newusermaindiv {
    display: flex;
    flex-direction: column;
  }

  .avatarborder {
    height: 6rem;
  }

  .inputfonts,
  .avatarleft {
    width: 25%;
  }

  .inputborder,
  .countryoption {
    width: 75%;
  }

  .newuserleft,
  .newuserright {
    width: 100%;
  }

  .form-button {
    display: flex;
  }

  .bottombuttons {
    margin-top: 2rem;
  }

  // .submitbutton,
  // .cancelbutton {
  //   width: 5rem;
  // }

  .avatarleft {
    width: 25%;
  }

  .avatarright {
    width: 75%;
  }

  .uploadlabel {
    width: 80%;
  }

  .chooselogo {
    width: 20%;
  }
}

@media screen and (min-device-width: 1000px) and (max-width: 1110px) {

  .inputfonts,
  .avatarleft,
  .uploadlogo {
    width: 30%;
  }

  .inputborder {
    width: 70%;
  }

  .countryoption {
    width: 70%;
  }

  .chooselogo {
    width: 70%;
  }

  .bottombuttons {
    margin-top: 4rem;
  }
}

/* NEW CLIENT..................................... */

.form-section {
  // height: calc(100vh - 182px);
  overflow-y: auto;
  overflow-x: hidden;
}

.uploadlogofile {
  width: 25%;
}

.fill {
  border: 1px solid var(--blue);
  line-height: 1.5;
  width: 75%;
}

.fill::file-selector-button {
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 0.2em;
}

/* ................................................ */

@media screen and (max-width: 540px) {

  .firstname,
  .middlename,
  .lastname,
  .address,
  .postalcode,
  .state,
  .userid,
  .city,
  .country,
  .mobilenumber,
  .officephone,
  .emailid,
  .designation {
    display: flex;
    flex-direction: column;
  }

  .inputborder,
  .countryoption {
    width: 100%;
  }

  .avatarleft {
    width: 0%;
  }

  .inputfonts {
    width: 100%;
  }

  .uploadsection {
    display: flex;
    flex-direction: column;
  }

  .chooselogo,
  .uploadlogo {
    width: 100%;
  }

  .fill {
    width: 100%;
  }
}

/* CDASH................................................ */

.cdashmenuclose {
  background: transparent;
  border: none;
  padding: 0px;
}

// .cdashmain {
//   margin-top: 2px;
// }

.navigation_bar {
  font-size: 12px;
  padding: 5px 7px;
  margin: 0;
  color: var(--white);
  border-bottom: 1px solid #668298;
  background: #668298;
}

.cdashleft {
  width: 65%;
  height: 100vh;
  border-right: 1px solid #e5e5e5 !important;
}

.Derived__veriable {
  width: 30%;
  height: 100vh;
  border-right: 1px solid #e5e5e5 !important;
}

.cdashright {
  width: 34.7%;
  margin-left: 5px;
  height: 100vh;
  border-left: 1px solid #e5e5e5 !important;
  position: relative;
}

.radiobar__chart__graph {
  h2 {
    padding-left: 20px;
    font-size: 17px;
    font-weight: 800;
    text-align: center;
  }

  p {
    margin: 0;
    font-size: 13px;
  }
}

// .SaveDeriver__button {
//   position: absolute;
//   right: 0;
//   top: 0;
// }

.Derived__veriable__right {
  width: 69.7%;
  margin-left: 5px;
  height: 100vh;
  border-left: 1px solid #e5e5e5 !important;
  position: relative;
}

.table-responsive-cdashleft {
  max-height: calc(100vh - 165px);
  overflow-y: auto;
  position: relative;
}

// .table-responsive-cdashrighttop {
//   max-height: 35vh;
//   overflow: auto;
//   position: relative;
// }

.cdashrightbottom {
  margin-top: 8px;
}

.table-responsive-cdashrightbottom {
  // max-height: 30vh;
  // overflow: auto;
  position: relative;
}

@media screen and (max-width: 1226px) {
  .cdashrighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 1060px) {
  .cdashlefttable {
    width: 140%;
  }
}

@media screen and (max-width: 949px) {
  .cdashrighttoptable {
    width: 170%;
  }
}

@media screen and (max-width: 893px) {
  .cdashrightbottomtable {
    width: 120%;
  }
}

@media screen and (max-width: 800px) {
  .cdashmain {
    flex-wrap: wrap;
  }

  .cdashleft,
  .cdashright {
    width: 100%;
  }

  .cdashleft,
  .table-responsive-cdashleft,
  .table-responsive-cdashrightbottom,
  .table-responsive-cdashrighttop {
    max-height: 23vh;
    overflow: auto;
  }

  .cdashright {
    height: 50vh;
  }

  .cdashrightbottom {
    margin-top: 8px;
  }

  .cdashright {
    margin-top: 5px;
    margin-left: 0px;
    border: none;
  }
}

@media screen and (max-width: 738px) {
  .cdashmain {
    margin-top: 10px;
  }

  .cdashleft {
    height: auto;
  }

  .table-responsive-cdashleft,
  .table-responsive-cdashrighttop,
  .table-responsive-cdashrightbottom {
    max-height: 20vh;
    overflow: auto;
  }
}

@media screen and (max-width: 507px) {
  .cdashlefttable {
    width: 180%;
  }
}

@media screen and (max-width: 417px) {
  .cdashmain {
    margin-top: 8px;
  }

  .table-responsive-cdashleft,
  .table-responsive-cdashrighttop,
  .table-responsive-cdashrighttop {
    max-height: 18vh;
    overflow: auto;
  }
}

@media screen and (max-width: 399px) {
  .cdashlefttable {
    width: 260%;
  }
}

.wrapper {
  position: absolute;
  border: 1px solid #cecece;
  border-radius: 3px;
  width: 200px;
  background: var(--white);
  box-shadow: 3px 4px 7px #bababa;
  z-index: 1;
}

.middle-content {
  position: relative;
}

.contextmenuoptions li a {
  text-decoration: none;
}

.contextmenuoptions li {
  text-decoration: none;
  list-style-type: none;
}

@media screen and (max-width: 1587px) {
  .customrighttop {
    width: 130%;
  }
}

@media screen and (max-width: 1226px) {
  .customrighttop {
    width: 150%;
  }
}

@media screen and (max-width: 1066px) {
  .customrighttop {
    width: 180%;
  }
}

@media screen and (max-width: 991px) {
  .customhistory {
    width: 100%;
  }
}

@media screen and (max-width: 1226px) {
  .customrighttop {
    width: 210%;
  }
}

@media screen and (max-width: 982px) {
  .customhistory {
    width: 117%;
  }
}

@media screen and (max-width: 761px) {

  .data-transfer,
  .custommain {
    display: flex;
    flex-direction: column;
  }

  .customrighttop,
  .customleftinner {
    width: 100%;
  }

  .cdashsecondtable,
  .historytt {
    width: 301%;
  }

  .customlefttable,
  .customrighttable {
    width: 100%;
    height: 50%;
  }
}

@media screen and (max-width: 627px) {

  .custommain,
  .data-transfer {
    display: flex;
    flex-direction: column;
  }

  .customlefttable {
    height: 40%;
    width: 100%;
  }

  .customrighttable {
    width: 100%;
  }
}

@media screen and (max-width: 539px) {
  .customrighttop {
    width: 120%;
  }
}

@media screen and (max-width: 1221px) {
  .schedulehistory {
    width: 130%;
  }
}

@media screen and (max-width: 1221px) {
  .schedulelefttable {
    width: 120%;
  }
}

@media screen and (max-width: 499px) {
  .schedulelefttable {
    width: 155%;
  }
}

@media screen and (max-width: 761px) {

  .scheduleleft,
  .scheduleright {
    width: 100%;
  }

  .scheduleleft {
    height: 50%;
  }
}

@media screen and (max-width: 991px) {
  /* navbar responsive css */

  // .edc-nav .navbar-toggler {
  //   background: var(--white);
  //   line-height: 1.4;
  //   margin-top: 7px;
  //   padding: 0px;
  //   font-size: 14px;
  // }

  .edc-nav .navbar-toggler {
    background: var(--white);
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;
    /* padding: 0px; */
    width: 35px;
    font-size: 14px;
  }

  button#navbarDropdown1 {
    margin-right: -166px;
    margin-top: 1px;
    margin-bottom: -40px;
  }

  .login {
    display: none;
  }

  .inputborder {
    width: 75%;
  }

  .countryoption {
    width: 75%;
  }

  .newuserleft,
  .newuserright {
    width: 100%;
  }

  .newusermaindiv {
    display: flex;
    flex-direction: column;
  }

  .bottombuttons {
    margin-top: 20px;
  }

  .inputfonts {
    width: 25%;
  }

  // .navbar {
  //   padding-top: 3px !important;
  // }

  // .navbar .nav-item {
  //   margin: 0px;
  //   border-bottom: 1px dashed #4c54a3;
  // }

  .filebars {
    justify-content: end;
    padding-left: 10px;
  }

  .filebar {
    margin-bottom: 8px;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  /* navbar responsive css end*/

  /* body content responsive css*/

  section.middle-content {
    height: 100%;
  }

  section.middle-content .sidebar-body {
    overflow-y: scroll;
  }

  .dashboard-body {
    width: 100%;
  }

  .navbox {
    visibility: hidden;
  }

  /* body content responsive css end*/
}

@media screen and (max-width: 540px) {

  .inputborder,
  .inputsize,
  .countryoption,
  .inputfonts {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .profiler {
    display: none;
  }

  button#navbarDropdown1 {
    margin-top: -15px;
  }
}

/* Lab Parameters ....................................... */

.dbdlabparameter {
  overflow: hidden;
}

.table-responsiveheight-lab {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

@media screen and (max-width: 1214px) {
  .labparameterlefttable {
    width: 130%;
    height: 150%;
  }

  .labparameterrighttable {
    width: 130%;
  }

  .labparameters {
    width: 99.7%;
  }
}

@media screen and (max-width: 950px) {
  .table-responsiveheight-lab {
    height: 35vh;
    overflow: auto;
  }

  .labparameters {
    display: flex;
    flex-direction: column;
  }

  .labtableleft {
    height: 33vh;
    width: 100%;
    border: none;
    overflow: hidden;
  }

  .labtableright {
    width: 100%;
    margin: 0px;
  }
}

@media screen and (max-width: 636px) {
  .labtableleft {
    margin-top: 8px;
  }
}

@media screen and (max-width: 609px) {
  .table-responsiveheight-lab {
    height: 28vh;
    overflow: auto;
  }
}

@media screen and (max-width: 517px) {
  .labparameterlefttable {
    width: 160%;
  }
}

@media screen and (max-width: 424px) {
  .labparameterlefttable {
    width: 200%;
  }
}

@media screen and (max-width: 336px) {
  .labparameterlefttable {
    width: 250%;
  }
}

/* ...................................................... */

/* Study Site .......................................... */

.squaredots {
  height: 18px;
}

@media screen and (max-width: 1320px) {
  .studysitetable {
    width: 130%;
  }
}

@media screen and (max-width: 1016px) {
  .studysitetable {
    width: 170%;
  }
}

/* ..................................................... */

/* STUDY RANDOMISATION.................................. */

@media screen and (max-width: 1430px) {
  .studyrandomrighttoptable {
    width: 120%;
  }
}

@media screen and (max-width: 1202px) {
  .studyrandomrighttoptable {
    width: 150%;
  }
}

@media screen and (max-width: 1083px) {
  .studyrandomlefttable {
    width: 130%;
  }
}

@media screen and (max-width: 969px) {
  .studyrandomrighttoptable {
    width: 180%;
  }
}

@media screen and (max-width: 838px) {
  .studyrandomlefttable {
    width: 150%;
  }
}

@media screen and (max-width: 816px) {
  .studyrandomrighttoptable {
    width: 210%;
  }
}

@media screen and (max-width: 800px) {
  .studyrandomrighttoptable {
    width: 100%;
  }
}

@media screen and (max-width: 499px) {
  .studyrandomrighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 484px) {
  .studyrandomlefttable {
    width: 180%;
  }
}

@media screen and (max-width: 407px) {
  .studyrandomlefttable {
    width: 210%;
  }
}

@media screen and (max-width: 389px) {
  .studyrandomrighttoptable {
    width: 170%;
  }
}

@media screen and (max-width: 407px) {
  .studyrandomlefttable {
    width: 270%;
  }
}

@media screen and (max-width: 554px) {
  .rntable {
    width: 140%;
  }
}

/* ..................................................... */

/* VISIT CRF............................................ */

.visitmain {
  margin-top: 2px;
}

.visitleft {
  width: 50%;
  height: 100vh;
  border-right: 1px solid #e5e5e5 !important;
}

.visitright {
  border-left: 1px solid #e5e5e5 !important;
  width: 49.6%;
  height: 100vh;
  margin-left: 5px;
}

.table-responsive-visit {
  max-height: calc(100vh - 144px);
  // height: auto;
  overflow: auto;
  position: relative;
}

@media screen and (max-width: 1379px) {
  .visitlefttable {
    width: 140%;
  }
}

@media screen and (max-width: 995px) {
  .visitmain {
    display: flex;
    flex-direction: column;
  }

  .table-responsive-visit {
    max-height: 35vh;
    overflow: auto;
  }

  .visitleft,
  .visitright {
    max-height: 41vh;
    border: none;
    margin-left: 0px;
  }

  .visitleft,
  .visitright {
    width: 100%;
  }
}

@media screen and (max-width: 608px) {
  .visitleft {
    margin-top: 3px;
  }
}

@media screen and (max-width: 502px) {
  .visitlefttable {
    width: 160%;
  }
}

@media screen and (max-width: 435px) {
  .visitlefttable {
    width: 180%;
  }

  .visitrighttable {
    width: 140%;
  }
}

/* ..................................................... */

/* CDISC DEMOGRAPHIC................................. */

.cdiscdemomain {
  margin-top: 2px;
}

@media screen and (max-width: 1582px) {
  .cdiscdemotable {
    width: 150%;
  }
}

@media screen and (max-width: 1061px) {
  .cdiscdemotable {
    width: 180%;
  }
}

@media screen and (max-width: 888px) {
  .cdiscdemotable {
    width: 250%;
  }
}

@media screen and (max-width: 645px) {
  .cdiscdemotable {
    width: 300%;
  }
}

@media screen and (max-width: 541px) {
  .cdiscdemotable {
    width: 380%;
  }
}

@media screen and (max-width: 432px) {
  .cdiscdemotable {
    width: 450%;
  }
}

@media screen and (max-width: 369px) {
  .cdiscdemotable {
    width: 550%;
  }
}

/* ..................................................... */

/* CDISC SETTING........................................ */

.cdiscmain {
  margin-top: 2px;
}

.selectcdisc {
  width: 72px;
  line-height: 10px;
}

@media screen and (max-width: 918px) {
  .cdisclefttable {
    width: 150%;
  }
}

@media screen and (max-width: 625px) {
  .cdiscmain {
    display: flex;
    flex-direction: column;
  }

  .cdisclefttable {
    width: 100%;
    border: none;
  }

  .settingright {
    width: 100%;
    margin-left: 0px;
  }

  .cdiscsettingrighttable {
    width: 100%;
  }

  .settingright {
    border: none;
  }
}

/* ..................................................... */

/* ADVERSE EVENT CODING................................. */

.custom_nav {
  position: relative;
  border-top: 1px solid #e5e5e5;
  gap: 15px;
}

.menuclose {
  // right: -37px;
  position: absolute;
  right: 0px;
  top: 10px;
}

.eventmain {
  height: 100vh;
  margin-top: 2px;
}

.adverseeventverb {
  width: 27%;
}

.adverseeventcoding-dashboard-body {
  overflow: hidden;
}

.table-responsive-eventcodingleft {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.table-responsive-eventcodingrighttop {
  max-height: 35vh;
  overflow: auto;
  position: relative;
}

.table-responsive-eventcodingrightbottom {
  max-height: 29vh;
  overflow: auto;
  position: relative;
}

.adverseeventrightbottom {
  margin-top: 10px;
}

.adverseeventright {
  width: 72.8%;
}

.adverseeventlefttable {
  width: 110%;
}

@media screen and (max-width: 1636px) {
  .adverseeventlefttable {
    width: 130%;
  }
}

// @media screen and (max-width: 1554px) {
//   .eventtable {
//     width: 160%;
//   }
// }

@media screen and (max-width: 1100px) {
  .adverseeventlefttable {
    width: 165%;
  }
}

@media screen and (max-width: 1027px) {
  .adverseeventlefttable {
    width: 200%;
  }
}

@media screen and (max-width: 991px) {
  .adverseeventhistorytable {
    width: 130%;
  }
}

@media screen and (max-width: 981px) {
  .adverseeventlefttable {
    width: 125%;
  }
}

@media screen and (max-width: 981px) {

  .adverseeventright,
  .adverseeventverb {
    width: 100%;
  }

  .table-responsive-eventcodingleft {
    height: 20vh;
    overflow: auto;
    margin-bottom: 8px;
  }

  .table-responsive-eventcodingrighttop {
    height: 23vh;
    margin-bottom: 14px;
  }

  .eventcodingtabbutton {
    padding: 5px;
  }

  .table-responsive-eventcodingrightbottom {
    height: 15vh;
    overflow: auto;
  }

  .adverseeventlefttable {
    width: 100%;
  }

  .eventmain {
    display: flex;
    flex-direction: column;
  }

  .eventleft {
    height: 50vh;
    width: 100%;
  }

  .adverseeventhistorytable {
    width: 100%;
  }

  .eventright {
    height: 60vh;
  }

  .eventtableleft {
    width: 100%;
  }

  .eventtableright {
    width: 130%;
  }
}

@media screen and (max-width: 808px) {
  .eventright {
    height: 80vh;
  }
}

@media screen and (max-width: 729px) {
  .adverseeventhistorytable {
    width: 130%;
  }
}

@media screen and (max-width: 659px) {
  .table-responsive-eventcodingleft {
    height: 15vh;
    overflow: auto;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 583px) {
  .adverseeventtermtable {
    width: 130%;
  }
}

@media screen and (max-width: 566px) {
  .adverseeventhistorytable {
    width: 160%;
  }
}

@media screen and (max-width: 454px) {
  .adverseeventhistorytable {
    width: 200%;
  }
}

@media screen and (max-width: 424px) {
  .adverseeventtermtable {
    width: 150%;
  }
}

@media screen and (max-width: 369px) {
  .adverseeventtermtable {
    width: 200%;
  }

  .adverseeventhistorytable {
    width: 300%;
  }
}

@media screen and (max-width: 355px) {
  .adverseeventtermtable {
    width: 200%;
  }

  .adverseeventhistorytable {
    width: 300%;
  }

  .adverseeventlefttable {
    width: 150%;
  }
}

/* ..................................................... */

/* BACKUP............................................... */

@media screen and (max-width: 1290px) {
  .backuprighttable {
    width: 140%;
  }
}

@media screen and (max-width: 950px) {
  .backuprighttable {
    width: 160%;
  }
}

@media screen and (max-width: 828px) {
  .backuprighttable {
    width: 170%;
  }
}

@media screen and (max-width: 774px) {
  .backupmain {
    display: flex;
    flex-direction: column;
  }

  .backupleft {
    width: 100%;
    height: 50vh;
  }

  .backuprightbottom {
    width: 100%;
    height: 50vh;
  }

  .backuprighttable {
    width: 100%;
    margin-left: 0px;
  }
}

@media screen and (max-width: 774px) {
  .backuprighttable {
    width: 130%;
  }
}

@media screen and (max-width: 479px) {
  .backuprighttable {
    width: 150%;
  }
}

/* ..................................................... */

/* USERS................................................ */

.table-responsive-userleftleft {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

// .table-responsive-userrighttop {
//   max-height: calc(100vh - 435px);
//   overflow: auto;
//   position: relative;
// }

.table-responsive-userrightbottom {
  max-height: 29vh;
  overflow: auto;
  margin-top: 16px;
  position: relative;
}

.usersmain {
  display: flex;
}

.userright {
  border-left: 1px solid #e5e5e5 !important;
  margin-left: 5px;
}

// @media screen and (max-width: 1285px) {
//   .userlefttable {
//     width: 130%;
//   }
// }

@media screen and (max-width: 1056px) {
  .userrightbottomtable {
    width: 130%;
  }
}

@media screen and (max-width: 981px) {
  .userlefttable {
    width: 150%;
  }

  .userrighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 853px) {
  .userlefttable {
    width: 170%;
  }
}

@media screen and (max-width: 810px) {
  .userrightbottomtable {
    width: 150%;
  }
}

@media screen and (max-width: 756px) {
  .usersmain {
    display: flex;
    flex-direction: column;
  }

  .userright {
    border-left: none;
    margin-left: 0px;
  }

  .table-responsive-userleftleft,
  .table-responsive-userrighttop {
    max-height: 25vh;
  }

  .table-responsive-userrightbottom {
    max-height: 20vh;
  }

  .table-responsive-userrightbottom {
    margin-top: 14px;
    height: 16vh;
  }

  .leftdiv {
    width: 100%;
    height: 25vh;
  }

  .userright {
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .userrightbottomtable {
    width: 130%;
  }
}

@media screen and (max-width: 509px) {
  .userrighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 432px) {
  .userrightbottomtable {
    width: 230%;
  }
}

@media screen and (max-width: 396px) {
  .userrighttoptable {
    width: 160%;
  }
}

@media screen and (max-width: 365px) {
  .userlefttable {
    width: 180%;
  }

  .leftdiv {
    width: 100%;
    height: 22vh;
  }

  .table-responsive-userleftleft,
  .table-responsive-userrighttop {
    max-height: 22vh;
  }
}

@media screen and (max-width: 346px) {
  .userlefttable {
    width: 220%;
  }
}

@media screen and (max-width: 326px) {
  .userrighttoptable {
    width: 200%;
  }
}

/* ..................................................... */

/* TABLE RESIZE COLUMN................................... */

// .table th {
// position: relative;
// width: auto;
// }

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  cursor: e-resize;
}

// .resizer:hover,
// .resizing {
//   border-right: 2px solid gray;
// }

/* ...................................................... */

/* TABLE CSS............................................. */

// th.one,
// td.one {
//   position: sticky;
//   left: 0;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

// th.two,
// td.two {
//   position: sticky;
//   left: 149px;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

// th.three,
// td.three {
//   position: sticky;
//   left: 298px;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

// th.four,
// td.four {
//   position: sticky;
//   left: 447px;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

// th.five,
// td.five {
//   position: sticky;
//   left: 596px;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

// th.six,
// td.six {
//   position: sticky;
//   left: 745px;
//   border-right: 2px solid var(--white);
//   background: #4d4a4a;
//   max-width: 20rem;
//   color: var(--white);
//   z-index: 5;
// }

.table>thead.thead-sticky {
  position: sticky !important;
  top: -1px;
  z-index: 99;
}

// tbody th {
//   position: -webkit-sticky;
//   position: sticky;
//   bottom: 0;
//   background: #666;
//   color: var(--white);
//   z-index: 4;
// }

// .sticky {
//   position: fixed;
//   top: 0;
//   width: 100%;
// }

// table {
//   white-space: nowrap;
// }

// td,
// th {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   position: relative;
// }

/* HIDE TABLE DATA WHILE RESIZING ENDS */

// .table-responsive {
//   overflow-y: hidden;
// }

// tr:nth-child(even) {
//   background-color: hsl(0deg 0% 94%) !important;
// }

// .secondtr {
//   background-color: hsl(0deg 0% 94%) !important;
// }

// .table {
//   table-layout: fixed;
//   --bs-table-bg: transparent !important;
//   text-wrap: nowrap;
//   position: relative;
// }

.table {
  // table-layout: fixed;
  --bs-table-bg: transparent !important;
  margin: 0;
}

#resizeMe thead,
#resizeMe1 thead,
#resizeMe2 thead,
#resizeMe3 thead,
#resizeMe4 thead,
#resizeMe5 thead,
#resizeMe6 thead {
  background-color: hsl(48deg 21% 86%);
}

#resizeMe tr,
#resizeMe1 tr,
#resizeMe2 tr,
#resizeMe3 tr,
#resizeMe4 tr,
#resizeMe5 tr,
#resizeMe6 tr {
  line-height: 13px;
}

#resizeMe th,
#resizeMe1 th,
#resizeMe2 th,
#resizeMe3 th,
#resizeMe4 th,
#resizeMe5 th,
#resizeMe6 th,
#resizeMe td,
#resizeMe1 td,
#resizeMe2 td,
#resizeMe3 td,
#resizeMe4 td,
#resizeMe5 td,
#resizeMe6 td {
  font-size: small;
  border-right: 1px solid var(--white);
  font-weight: initial;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
}

.print_font td {
  font-size: small;
}

.react-contextmenu-wrapper {
  font-weight: initial;
  position: relative;
  // max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// .studyIdLists {
//   background: hsl(0, 0%, 85%) !important;
// }

/* ..................................................... */

/* COLUMN DELETE................................................ */

/* ..................................................... */

/* Client................................................ */

.clienttable-dashboard-body {
  overflow: hidden;
}

.clientleftdiv {
  width: 48%;
  height: 100vh;
}

.table-responsive-clienttablerighttop {
  max-height: 30vh;
  margin-bottom: 10px;
  overflow: auto;
  position: relative;
}

.table-responsive-clienttablebottom {
  max-height: 35vh;
  overflow: auto;
  position: relative;
}

.clientrighttop {
  margin-bottom: -10px;
}

.clientright {
  width: 52%;
  height: 100vh;
}

.table-responsive-clienttableleft {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.clientmain {
  margin-top: 2px;
}

@media screen and (max-width: 1285px) {
  .clientlefttable {
    width: 130%;
  }
}

@media screen and (max-width: 964px) {
  .clientrighttoptable {
    width: 130%;
    overflow: scroll;
  }
}

@media screen and (max-width: 916px) {
  .clientlefttable {
    width: 150%;
  }
}

@media screen and (max-width: 832px) {
  .clientrightbottomtable {
    width: 130%;
  }
}

.clientright {
  margin-left: 6px;
}

@media screen and (max-width: 787px) {
  .clientmain {
    display: flex;
    flex-direction: column;
  }

  .table-responsive-clienttable {
    max-height: 25vh;
    overflow: auto;
  }

  .table-responsive-clienttablebottom {
    height: 17vh;
    overflow: auto;
  }

  .clientleftdiv {
    height: 26vh;
  }

  .clientrighttop {
    margin-bottom: 7px;
  }

  .clientleftdiv,
  .clientright {
    width: 100%;
  }

  .clientleftdiv {
    height: 28vh;
  }

  .clientrighttoptable {
    width: 100%;
  }

  .clientright {
    margin-left: 0px;
  }

  .clientlefttable {
    width: 100%;
  }
}

@media screen and (max-width: 617px) {
  .clientmain {
    margin-top: 7px;
  }

  .table-responsive-clienttable {
    height: 20vh;
    overflow: auto;
  }

  .clientleftdiv {
    height: 21vh;
  }
}

@media screen and (max-width: 608px) {
  .clientmain {
    margin-top: 8px;
  }

  .table-responsive-clienttablebottom {
    margin-top: 15px;
    height: 19vh;
  }

  .clienttablepagination {
    margin-top: 2px;
  }

  .userspan {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 572px) {
  .clientlefttable {
    width: 130%;
  }
}

@media screen and (max-width: 560px) {
  .userrightbottomtable {
    width: 130%;
  }
}

@media screen and (max-width: 509px) {
  .clientrighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 509px) {
  .clientlefttable {
    width: 150%;
  }
}

@media screen and (max-width: 396px) {
  .clientrighttoptable {
    width: 160%;
  }
}

@media screen and (max-width: 379px) {
  .clientlefttable {
    width: 200%;
  }
}

@media screen and (max-width: 358px) {
  .clientrightbottomtable {
    width: 170%;
  }
}

@media screen and (max-width: 326px) {
  .clientrighttoptable {
    width: 200%;
  }
}

@media screen and (max-width: 323px) {
  .clientmain {
    margin-top: 5px;
  }
}

/* ..................................................... */

/* ASSIGN STUDY......................................... */

.table-responsive-assignstudy {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.assignstudytable {
  margin-top: 2px;
}

@media screen and (max-width: 706px) {
  .assignstudytable {
    width: 130%;
  }

  .assignstudy {
    margin-top: 5px;
  }
}

@media screen and (max-width: 545px) {
  .assignstudytable {
    width: 150%;
  }
}

@media screen and (max-width: 474px) {
  .assignstudytable {
    width: 170%;
  }
}

@media screen and (max-width: 412px) {
  .assignstudytable {
    width: 200%;
  }
}

@media screen and (max-width: 353px) {
  .assignstudytable {
    width: 300%;
  }
}

/* ...................................................... */

/* ADVERSE EVENT......................................... */

// .adverseevent {
//   margin-top: 2px;
// }

/* ....................................................... */

/* ADVERSEEVENT1.......................................... */

@media screen and (max-width: 661px) {
  .eventtable1 {
    width: 130%;
  }
}

@media screen and (max-width: 511px) {
  .eventtable1 {
    width: 150%;
  }
}

@media screen and (max-width: 434px) {
  .eventtable1 {
    width: 180%;
  }
}

@media screen and (max-width: 434px) {
  .eventtable1 {
    width: 220%;
  }
}

/* ...................................................... */

/* CREATE ROLE.......................................... */

.createrolemain {
  margin-top: 3px;
}

@media screen and (max-width: 1287px) {
  .createrolerighttoptable {
    width: 130%;
  }
}

@media screen and (max-width: 1001px) {

  .createrolerighttoptable,
  .createrolerightbottomtable {
    width: 150%;
  }
}

@media screen and (max-width: 870px) {

  .createrolerighttoptable,
  .createrolerightbottomtable {
    width: 170%;
  }
}

@media screen and (max-width: 814px) {

  .createrolerighttoptable,
  .createrolerightbottomtable {
    width: 100%;
  }

  .createrolemain {
    display: flex;
    flex-direction: column;
  }

  .createroleleft,
  .createroleright {
    width: 100%;
  }

  .createroleleft,
  .createrolerightbottom,
  .createrolerighttop {
    width: 100%;
  }
}

@media screen and (max-width: 517px) {
  .createrolelefttable {
    width: 130%;
  }
}

@media screen and (max-width: 434px) {
  .createrolelefttable {
    width: 120%;
  }
}

@media screen and (max-width: 416px) {
  .createrolerighttoptable {
    width: 120%;
  }
}

@media screen and (max-width: 404px) {
  .createrolelefttable {
    width: 160%;
  }
}

@media screen and (max-width: 351px) {
  .createrolerighttoptable {
    width: 150%;
  }
}

@media screen and (max-width: 331px) {
  .createrolelefttable {
    width: 190%;
  }
}

@media screen and (max-width: 328px) {
  .createrolerightbottomtable {
    width: 120%;
  }
}

/* ..................................................... */

/* CREATEROLE2.......................................... */

@media screen and (max-width: 1152px) {
  .roletext {
    width: 20%;
  }
}

@media screen and (max-width: 1024px) {

  .rolename,
  .roledescription,
  .createroleselect {
    width: 37%;
  }
}

@media screen and (max-width: 720px) {
  .roleform {
    display: flex;
    flex-direction: column;
  }

  .rolename,
  .createroleselect {
    width: 55%;
  }

  .roledescription {
    width: 55%;
    height: 105px;
  }

  .roletext {
    width: 100%;
  }

  .createrolebuttons {
    margin-top: 1rem;
  }
}

/* ..................................................... */

@media screen and (max-device-width: 1143px) {
  .subject {
    width: 9.9%;
  }

  .clientnametextareas {
    width: 86%;
  }

  .subjectinput {
    width: 90.1%;
  }

  .useremail {
    width: 14%;
  }

  .newreqemailid {
    width: 86%;
  }

  .requesttypelabel {
    width: 40%;
  }

  .selectrequesttype {
    width: 60%;
  }

  .projectidlabel {
    width: 30%;
  }

  .projectidinput {
    width: 70%;
  }

  .studyidlabel {
    width: 25%;
  }

  .studyidinput {
    width: 75%;
  }

  .clientlabel {
    width: 14%;
  }

  .clientname {
    height: 2.3rem;
    border-radius: 5px;
    border: 1px solid hsl(195deg 86% 53%);
    width: 89.3%;
    padding-left: 15px;
  }

  .clientnamediv {
    width: 100%;
  }

  .protocolversionlabel {
    width: 26%;
  }

  .protocolversioninput {
    width: 44%;
  }

  .expectedenrollmentlabel {
    width: 49%;
  }

  .expectedenrollmentinput {
    height: 2.3rem;
    border-radius: 5px;
    border: 1px solid hsl(195deg 86% 53%);
    width: 60%;
  }

  .studytypes {
    width: 13.6%;
  }

  .herapeutics {
    width: 20%;
  }
}

/* DATABSE LOCK REQUEST.................... */

@media screen and (max-device-width: 1033px) {
  .lockclientname {
    width: 65%;
  }

  .lockclientnamee {
    width: 35%;
  }
}

@media screen and (max-device-width: 966px) {
  .lockproject {
    width: 35%;
  }

  .lockprojectid {
    width: 65%;
  }

  .lockusermail,
  .locksubject {
    display: flex;
    flex-direction: column;
  }

  .locksubject {
    padding-left: 0px;
  }

  .lockmailinput,
  .locksubjectinput,
  .locksubjects,
  .lockmaillabel {
    width: 100%;
  }

  .attach {
    width: 236px;
    height: 46px;
  }

  .cdadraft {
    font-size: 11px;
  }

  .lockreqtype {
    display: flex;
    flex-direction: column;
  }

  .lockrequesttypelabel,
  .lockclientnamee,
  .lockproject,
  .lockstudyidlabel,
  .lockrequestdetaillabel,
  .lockstatuslabel {
    width: 20%;
  }

  .lockselectrequesttype,
  .lockclientname,
  .lockprojectid,
  .lockstudyidselect,
  .locktextarea {
    width: 80%;
  }

  .lockprojectid {
    margin-top: 5px;
  }

  .lockreq {
    width: 100%;
  }

  .lockrequesttype {
    padding-left: 9px;
  }

  .lockstudyid {
    padding-left: 33px;
  }

  textarea.clientnametextareas.form-control.locktextarea,
  .lockstatusselect {
    width: 79%;
    margin-left: 7px;
  }

  .lockformsection {
    height: 80vh;
  }
}

@media screen and (max-device-width: 672px) {

  .lockrequesttype,
  .lockreq,
  .locktextareacontainer,
  .lockstatus {
    display: flex;
    flex-direction: column;
  }

  .lockselectrequesttype,
  .lockclientname,
  .lockprojectid,
  .lockstudyidselect,
  .locktextarea,
  .lockstatusselect,
  .lockstatuslabel {
    width: 100%;
  }

  textarea.clientnametextareas.form-control.locktextarea {
    width: 99%;
    margin-top: -20px;
  }

  .lockrequestdetaillabel {
    padding-left: 10px;
    width: 100%;
  }

  .lockrequesttypelabel,
  .lockclientnamee,
  .lockproject,
  .lockstudyidlabel,
  .lockrequestdetaillabel {
    width: 100%;
  }

  .lockformsection {
    height: 120vh;
  }

  .lockstatuslabel {
    padding-left: 10px;
  }
}

/* ......................................... */

/* DEMOGRAPHIC2............................. */

tr.demodemo {
  background: transparent;
}

.table-responsive-demographic2 {
  max-height: 36vh;
  overflow: auto;
  position: relative;
}

.demographiccancelbutton,
.demographicsubmitbutton {
  background-color: var(--blue);
  color: var(--white);
  width: 80px;
  height: 30px;
  padding: 2px;

  &:hover {
    background: transparent !important;
    border: 1px solid var(--blue) !important;
    color: var(--blue);
  }
}

@media screen and (max-device-width: 1627px) {
  .demo2genderflex {
    display: flex;
    flex-direction: column;
    height: 53vh;
  }
}

@media screen and (max-device-width: 1100px) {

  .demographiccancelbutton,
  .demographicsubmitbutton {
    background-color: var(--blue);
    color: var(--white);
    width: 65px;
    height: 29px;
    padding: 2px;
    font-size: 13px;
  }
}

@media screen and (max-device-width: 1014px) {
  .table-responsive-demographic2 {
    height: 26vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 981px) {
  .querytable {
    width: 200%;
  }
}

@media screen and (max-device-width: 1627px) {
  .demo2genderflex {
    height: 48vh;
  }
}

@media screen and (max-device-width: 813px) {
  .querytable {
    width: 250%;
  }
}

@media screen and (max-device-width: 680px) {
  .demo2genderflex {
    display: flex;
    flex-direction: row;
  }

  .demo2genderflex {
    height: 5vh;
  }

  .demographic2main {
    display: flex;
    flex-direction: column;
  }

  .demo2left {
    width: 100%;
    padding-left: 25px;
    padding-bottom: 10px;
  }

  .demographic2column {
    width: 100%;
    margin-left: 0px;
    border: none;
  }

  .demographic2main {
    width: 100%;
    height: calc(100vh - 182px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.custom_nav {
  .demo2tabbutton {
    padding: 6px 4px;
    font-size: 14px;
    height: 35px;
  }
}

.demo2table {

  th,
  td {
    font-weight: 400 !important;
  }
}

@media screen and (max-device-width: 1014px) {
  // .demo2tabbutton {
  //   padding: 8px;
  // }
}

@media screen and (max-device-width: 813px) {
  .querytable {
    width: 300%;
  }
}

@media screen and (max-device-width: 369px) {
  .querytable {
    width: 400%;
  }
}

@media screen and (max-device-width: 535px) {
  .demohistorytable {
    width: 130%;
  }
}

@media screen and (max-device-width: 513px) {
  .demo2table {
    width: 150%;
  }
}

@media screen and (max-device-width: 459px) {
  .demo2table {
    width: 170%;
  }
}

@media screen and (max-device-width: 417px) {
  .demohistorytable {
    width: 180%;
  }
}

@media screen and (max-device-width: 361px) {
  .demo2table {
    width: 190%;
  }
}

@media screen and (max-device-width: 326px) {
  .demo2table {
    width: 230%;
  }
}

@media screen and (max-device-width: 323px) {
  .demo2genderflex {
    height: 10vh;
  }
}

/* ......................................... */

/* Demographic.............................. */

.crfBirth {
  width: 223px;
}

.demographicbyear,
.demographicspace,
.demographicc200,
.demographicbirth {
  width: 25%;
}

.demographicbottomsection {
  width: 97%;
  margin: auto;
  padding-top: 20px;
}

.striped-border {
  border-bottom: dashed 2px #e5e5e5 !important;
}

.demographicfloatbutton {
  background-color: transparent;
  border: none;
}

.crfleftpills {
  display: flex;
  flex-direction: column;
}

.vheightleft {
  width: 50%;
}

.vheightright {
  width: 50%;
}

.vheightright {
  height: calc(100vh - 182px);
  overflow: auto;
}

.vheightselect {
  margin-bottom: 10px;
}

.vselectlabel {
  width: 25%;
}

.vselectselect {
  width: 75%;
}

.vheightleft {
  border-right: 1px solid #e5e5e5;
  height: 100vh;
}

.tvbutton {
  background-color: var(--blue);
  color: var(--white);
  width: 35%;
  padding: 0px;
  height: 30px;
  border-radius: 6px;
}

.vheight,
.vadd {
  background-color: var(--blue);
  color: var(--white);
  width: 70px;
  padding: 0px;
  height: 30px;
  border-radius: 6px;
}

.tvalidationtext {
  border: 2px solid var(--blue);
  width: 70%;
  margin-right: 10px;
  height: 30px;
  border-radius: 6px;
}

.vbutton {
  background-color: transparent;
  border: none;
}

.addvariable {
  background-color: var(--blue);
  border: none;
  border-radius: 6px;
  color: var(--white);
  width: 100%;
  margin: auto;
}

.sedright {
  width: 40%;
  border-left: 1px solid #e5e5e5;
  margin-left: 5px;
  height: 100vh;
}

.sedleft {
  width: 60%;
  border-right: 1px solid #e5e5e5;
  height: 100vh;
}

.sdvlabel {
  width: 30%;
}

.sdvselect {
  border: 2px solid var(--blue);
  border-radius: 6px;
  width: 70%;
}

.crfnav {
  border-bottom: 1px solid #e5e5e5;
}

.venter {
  width: 95%;
  margin: auto;
}

.vheightform {
  height: 58vh;
}

.vselectselect {
  border: 2px solid var(--blue);
  border-radius: 6px;
  height: 28px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.vheightbottom {
  background: hsl(0deg 0% 95%);
  padding-top: 10px;
  padding-bottom: 20px;
}

.vheighttop {
  height: 50vh;
}

.leftbox {
  padding-top: 10px;
}

.table-responsive-sedleft {
  max-height: calc(100vh - 220px);
  overflow: auto;
  position: relative;
}

.table-responsive-demoeditor {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.table-responsive-historyweight {
  height: 65vh;
  overflow: auto;
  position: relative;
}

@media screen and (max-device-width: 1239px) {
  .sdvlabel {
    width: 35%;
  }

  .sdvselect {
    width: 65%;
  }
}

@media screen and (max-device-width: 1072px) {
  .sdvlabel {
    width: 40%;
  }

  .sdvselect {
    width: 60%;
  }
}

@media screen and (max-device-width: 947px) {
  .sdvlabel {
    width: 45%;
  }

  .sdvselect {
    width: 55%;
  }
}

@media screen and (max-device-width: 940px) {
  .sdvlabel {
    width: 100%;
  }

  .sdvselect {
    width: 100%;
  }
}

@media screen and (max-device-width: 1244px) {
  .vselectlabel {
    width: 30%;
  }

  .vselectselect {
    width: 70%;
  }
}

@media screen and (max-device-width: 1088px) {
  .vselectlabel {
    width: 35%;
  }

  .vselectselect {
    width: 65%;
  }
}

@media screen and (max-device-width: 1027px) {
  .vselectlabel {
    width: 40%;
  }

  .vselectselect {
    width: 60%;
  }
}

@media screen and (max-device-width: 940px) {
  .vheightselect {
    display: flex;
    flex-direction: column;
  }

  .vheightform {
    height: 90vh;
  }

  .vheightright {
    height: calc(100vh - 182px);
    overflow: auto;
  }

  .vselectlabel {
    width: 100%;
  }

  .vselectselect {
    width: 100%;
  }
}

@media screen and (max-device-width: 896px) {
  .table-responsive-demoeditor {
    height: 40vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 869px) {
  .crfmain {
    display: flex;
    flex-direction: column;
  }

  .table-responsive-historyweight {
    height: 55vh;
  }

  .genderselect {
    border: none;
    margin-left: 0px;
  }

  .crfleft,
  .rightbox,
  .leftbox {
    width: 100%;
  }

  .crfleftpills {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}

@media screen and (max-device-width: 859px) {
  .demoeditortable {
    width: 130%;
  }
}

@media screen and (max-device-width: 851px) {
  .deographic-tab {
    padding: 5px 10px 5px 10px;
  }

  .vheightright {
    height: 64vh;
    overflow: auto;
  }

  .vheighttop {
    height: 43vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 759px) {
  .vheighttop {
    height: 39vh;
    overflow: auto;
  }

  .deographic-tab {
    padding: 4px 6px 4px 8px;
  }

  .vheightright {
    height: 60vh;
    overflow: auto;
  }

  .leftbox {
    padding-top: 7px;
  }
}

@media screen and (max-device-width: 714px) {
  .vheightright {
    height: 55vh;
    overflow: auto;
  }

  .table-responsive-historyweight {
    height: 50vh;
  }

  .table-responsive-sedleft {
    max-height: calc(100vh - 242px);
    overflow: auto;
  }
}

@media screen and (max-device-width: 658px) {
  .testtablediv {
    width: 100%;
  }

  .testtable {
    width: 130%;
  }
}

@media screen and (max-device-width: 559px) {
  .sedtable {
    width: 130%;
  }
}

@media screen and (max-device-width: 547px) {
  .historyweighttable {
    width: 130%;
  }
}

@media screen and (max-device-width: 514px) {
  .vheight {
    display: block;
    margin-bottom: 5px;
  }

  .vheightright {
    height: 50vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 511px) {
  .testtablediv {
    width: 100%;
  }

  .testtable {
    width: 180%;
  }
}

@media screen and (max-device-width: 505px) {
  .vheighttop {
    height: 33vh;
    overflow: auto;
  }

  .table-responsive-historyweight {
    height: 46vh;
  }
}

@media screen and (max-device-width: 438px) {
  .sedtable {
    width: 180%;
  }
}

@media screen and (max-device-width: 426px) {
  .historyweighttable {
    width: 170%;
  }
}

@media screen and (max-device-width: 424px) {
  .table-responsive-sedleft {
    max-height: calc(100vh - 280px);
    overflow: auto;
  }

  .table-responsive-historyweight {
    height: 41vh;
  }

  .vheightright {
    height: 45vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 418px) {
  .vheightright {
    height: 40vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 336px) {
  .table-responsive-historyweight {
    height: 39vh;
  }
}

@media screen and (max-device-width: 330px) {
  .historyweighttable {
    width: 210%;
  }
}

@media screen and (max-device-width: 328px) {
  .sedtable {
    width: 230%;
  }
}

/* ............................................ */

/* DATAENTRY DEMOGRAPHICS....................... */

.dataentryleft {
  width: 20%;
}

.dataentrydemographicright {
  width: 78%;
  margin-left: 15px;
}

@media screen and (max-device-width: 686px) {
  .dataentryleft {
    width: 30%;
  }

  .dataentrydemographicright {
    width: 68%;
  }
}

@media screen and (max-device-width: 680px) {
  .demographicscreaterolebuttons {
    padding-top: 2px;
  }
}

@media screen and (max-device-width: 555px) {
  .dataentryleft {
    width: 40%;
  }

  .dataentrydemographicright {
    width: 58%;
  }
}

/* ............................................ */

@media screen and (max-device-width: 608px) {
  .jobcreationpagination {
    margin-top: -3px;
  }
}

/* DATA SETTING ................................... */

.datasettingmain {
  margin-top: 2px;
}

@media screen and (max-device-width: 1462px) {
  .datasettingtable {
    width: 130%;
  }
}

@media screen and (max-device-width: 1127px) {
  .datasettingtable {
    width: 150%;
  }
}

@media screen and (max-device-width: 978px) {
  .datasettingtable {
    width: 170%;
  }
}

@media screen and (max-device-width: 866px) {
  .datasettingtable {
    width: 200%;
  }
}

@media screen and (max-device-width: 736px) {
  .datasettingtable {
    width: 250%;
  }
}

@media screen and (max-device-width: 591px) {
  .datasettingtable {
    width: 300%;
  }
}

@media screen and (max-device-width: 495px) {
  .datasettingtable {
    width: 400%;
  }
}

@media screen and (max-device-width: 374px) {
  .datasettingtable {
    width: 500%;
  }
}

/* ................................................. */

/* METHODS.......................................... */

.methodbottoms {
  height: 15vh;
  overflow: auto;
}

.table-responsive-methodleft {
  height: 45vh;
  overflow: auto;
  position: relative;
}

.methodtopleft {
  width: 60%;
}

.methodtopright {
  width: 40%;
}

.methodrightform {
  height: 45vh;
  overflow: auto;
}

.home-lines {
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.methodrights {
  display: flex;
  flex-direction: column;
  width: 84.6%;

  margin-left: 5px;
  border-left: 1px solid #e5e5e5;
}

.methodleft,
.methodleftside {
  width: 15%;
  height: 100vh;
}

.methodflex {
  display: flex;
  flex-direction: column;
}

.methodright {
  width: 100%;
  height: 45vh;
}

.methodmain {
  margin-top: 2px;
}

.methodrighttable {
  width: 100%;
}

.methodselect {
  width: 95px;
  display: inline;
  height: 27px;
  padding-left: 3px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 2px;
}

.methodselectspantext {
  padding-left: 5px;
  padding-right: 0px;
}

@media screen and (max-device-width: 1453px) {
  .methodrighttable {
    width: 150%;
  }
}

@media screen and (max-device-width: 1166px) {
  .methodrighttable {
    width: 170%;
  }
}

@media screen and (max-device-width: 1032px) {
  .methodrighttable {
    width: 190%;
  }
}

@media screen and (max-device-width: 925px) {
  .methodrighttable {
    width: 230%;
  }
}

@media screen and (max-device-width: 768px) {
  .methodrighttable {
    width: 260%;
  }
}

@media screen and (max-device-width: 682px) {
  .methodrighttable {
    width: 290%;
  }
}

@media screen and (max-device-width: 616px) {
  .methodmain {
    flex-wrap: wrap;
  }

  .methodleftside {
    width: 100%;
    height: 4vh;
  }

  .methodflex {
    display: flex;
    padding-bottom: 5px;
    flex-direction: row;
  }

  .methodleft,
  .methodrights {
    width: 100%;
  }

  .methodright {
    height: 30vh;
  }

  .methodrights {
    margin-left: 0px;
    border: none;
  }

  .methodrightform {
    height: 27vh;
    overflow: auto;
  }

  .table-responsive-methodleft {
    height: 27vh;
    overflow: auto;
  }
}

/* .................................................. */

/* CLIENT BACKUP TABLE............................ */

.clientbackuptablemain {
  height: 100vh;
}

.clientbackuptableleft {
  width: 60%;
}

.clientbackuptableright {
  width: 39.6%;
  margin-left: 5px;
}

@media screen and (max-device-width: 1202px) {
  .clientbackuptablerighttable {
    width: 130%;
  }
}

@media screen and (max-device-width: 931px) {
  .clientbackuptablerighttable {
    width: 150%;
  }
}

@media screen and (max-device-width: 905px) {
  .clientbackuptablelefttable {
    width: 130%;
  }
}

@media screen and (max-device-width: 808px) {
  .clientbackuptablerighttable {
    width: 170%;
  }
}

@media screen and (max-device-width: 716px) {
  .clientbackuptablemain {
    display: flex;
    flex-direction: column;
  }

  .clientbackuptableleft,
  .clientbackuptableright {
    width: 100%;
  }

  .clientbackuptablerighttable,
  .clientbackuptablelefttable {
    width: 100%;
  }
}

@media screen and (max-device-width: 549px) {

  .clientbackuptablelefttable,
  .clientbackuptablerighttable {
    width: 130%;
  }
}

@media screen and (max-device-width: 387px) {

  .clientbackuptablelefttable,
  .clientbackuptablerighttable {
    width: 170%;
  }
}

/* ................................................ */

/* BACKUP.......................................... */

.backuptoptions {
  width: 100%;
}

.backuplabels {
  width: 11%;
  padding-top: 5px;
  text-align: right;
}

.backupoptions {
  width: 20%;
  height: 32px;
}

@media screen and (max-device-width: 1552px) {
  .backuplabels {
    width: 12%;
  }
}

@media screen and (max-device-width: 1429px) {
  .backuplabels {
    width: 13%;
  }
}

@media screen and (max-device-width: 1324px) {
  .backuplabels {
    width: 14%;
  }
}

@media screen and (max-device-width: 1235px) {
  .backuplabels {
    width: 15%;
  }
}

@media screen and (max-device-width: 1157px) {
  .backuplabels {
    width: 16%;
  }

  .backupoptions {
    width: 23%;
  }
}

@media screen and (max-device-width: 1090px) {
  .backuplabels {
    width: 17%;
  }

  .backupoptions {
    width: 23%;
  }
}

@media screen and (max-device-width: 1030px) {
  .backuplabels {
    width: 18%;
  }

  .backupoptions {
    width: 25%;
  }
}

@media screen and (max-device-width: 976px) {
  .backuplabels {
    width: 19%;
  }

  .backupoptions {
    width: 25%;
  }
}

@media screen and (max-device-width: 929px) {
  .backuplabels {
    width: 21%;
  }

  .backupoptions {
    width: 28%;
  }
}

@media screen and (max-device-width: 847px) {
  .backuplabels {
    width: 23%;
  }

  .backupoptions {
    width: 31%;
  }
}

@media screen and (max-device-width: 780px) {
  .backuplabels {
    width: 26%;
  }

  .backupoptions {
    width: 35%;
  }
}

@media screen and (max-device-width: 698px) {
  .backuplabels {
    width: 30%;
  }

  .backupoptions {
    width: 40%;
  }
}

@media screen and (max-device-width: 615px) {
  .backuptoptions {
    display: flex;
  }

  .backuplabels {
    width: 35%;
    text-align: left;
  }

  .backupoptions {
    width: 65%;
  }

  .backupdashboard {
    height: 100vh;
  }
}

@media screen and (max-device-width: 547px) {
  .backuptoptions {
    display: flex;
  }

  .backuplabels {
    width: 42%;
    text-align: left;
  }

  .backupoptions {
    width: 58%;
  }

  .backupdashboard {
    height: 100vh;
  }
}

@media screen and (max-device-width: 470px) {
  .backuptoptions {
    display: flex;
    flex-direction: column;
  }

  .backuplabels {
    width: 100%;
    text-align: left;
  }

  .backupoptions {
    width: 100%;
  }

  .backupdashboard {
    height: 100vh;
  }
}

/* ................................................. */

/* QUERY MANAGEMENT................................. */

.querymodel {
  position: relative;
  pointer-events: auto;
  background-clip: padding-box;
  border: 2px solid var(--blue);
  // border-radius: 0.6rem;
  outline: 0;
  height: 100%;
  min-height: 164px;
  background-color: var(--white);
  padding-bottom: 1rem;
}

.modalcross {
  background-color: transparent;
  border: none;
  color: hsl(202deg 56% 53%);
}

.querymodals-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0 20px;
  align-items: center;
  display: flex;
  text-align: center;
  line-height: 1.6;
  font-size: 16px;
}

.queryselectvisit,
.queryselectsubject,
.queryselectsite {
  display: flex;
  width: 33.33%;
}

.queryvisitlabel,
.querysubjectlabel,
.querysitelabel {
  width: 30%;
  padding-top: 10px;
  padding-left: 3px;
  padding-right: 5px;
  text-align: right;
}

.queryvisit,
.querysite,
.querysubject {
  width: 70%;
  height: 32px;
  border: 1px solid var(--blue);
}

.querysecondhr {
  margin-top: 81px;
  margin-bottom: 5px;
}

.querytableselect {
  border: none;
  background-color: transparent;
  opacity: 0.8;
  width: 85px;
  cursor: pointer;
}

.querypopup1hr {
  // margin-top: 2px;
  margin: 0px;
}

.popupcross {
  font-size: 21px;
}

.querypopuptablemain {
  height: 50vh;
}

.querymodaldropdowns {
  padding-top: 10px;
}

@media screen and (max-device-width: 1644px) {
  .querypopuptablemain {
    height: 43vh;
  }
}

@media screen and (max-device-width: 1026px) {
  .querypopuptablemain {
    height: 40vh;
  }

  .queryvisitlabel,
  .querysubjectlabel,
  .querysitelabel {
    width: 40%;
  }

  .queryvisit,
  .querysite,
  .querysubject {
    width: 60%;
  }
}

@media screen and (max-device-width: 783px) {

  .queryvisitlabel,
  .querysubjectlabel,
  .querysitelabel {
    width: 50%;
  }

  .queryvisit,
  .querysite,
  .querysubject {
    width: 50%;
  }
}

@media screen and (max-device-width: 722px) {
  .querypopuptable {
    width: 130%;
  }
}

@media screen and (max-device-width: 636px) {
  .querypopuptable {
    width: 130%;
  }

  .querypopuptablemain {
    height: 30vh;
  }

  .queryselectvisit,
  .queryselectsubject,
  .queryselectsite {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .queryvisitlabel,
  .querysubjectlabel,
  .querysitelabel {
    width: 100%;
    text-align: left;
  }

  .queryvisit,
  .querysite,
  .querysubject {
    width: 95%;
  }
}

@media screen and (max-device-width: 564px) {
  .querypopuptable {
    width: 150%;
  }
}

@media screen and (max-device-width: 491px) {
  .querypopuptable {
    width: 180%;
  }
}

@media screen and (max-device-width: 412px) {
  .querypopuptable {
    width: 200%;
  }
}

/* ................................................. */

/*  CONTEXT MENU.................................... */

.contextmenuoptions {
  position: absolute;
  border: 1px solid #cecece;
  border-radius: 3px;
  background: var(--white);
  box-shadow: 3px 4px 7px #bababa;
  z-index: 1;
  transition: opacity 0.5s linear;
}

.contextmenuoptions {
  padding: 0px;
  margin: 0px;
  min-width: 150px;
  list-style: none;
}

.contextmenuoptions li {
  padding-bottom: 7px;
  padding-top: 7px;
  border: 1px solid black;
}

.contextmenuoptions li a {
  text-decoration: none;
  color: black;
}

.contextmenuoptions li:hover {
  background: darkgray;
}

/* ............. */

.menu {
  background-color: var(--color-bg-secondary);
  border-radius: 10px;
  box-shadow: 0 10px 20px #40404015;
}

.menu-list {
  margin: 0;
  display: block;
  width: 100%;
  padding: 0px;
}

.menu-list+.menu-list {
  border-top: 1px solid #ddd;
}

.menu-sub-list:hover {
  display: flex;
}

li.menu-item:hover {
  background-color: var(--blue);
  color: var(--white);
}

button.menu-button:hover {
  color: var(--white);
}

button.menu-button {
  padding: 5px 5px 5px 12px;
}

.menu-sub-list {
  display: none;
  padding: 0px;
  background-color: var(--color-bg-secondary);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(#404040, 0.15);
  position: absolute;
  left: 100%;
  right: 0;
  z-index: 100;
  width: 100%;
  top: 0;
  flex-direction: column;
}

.sub-left .menu-sub-list {
  left: -100%;
  right: 0;
}

.menu-item {
  position: relative;
}

.menu-button:hover {
  background-color: var(--color-bg-primary-offset);
}

.menu-button:hover+.menu-sub-list {
  display: flex;
}

.menu-button:hover svg {
  stroke: var(--color-text-primary);
}

.menu-button {
  font: inherit;
  border: 0;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-bg-secondary);
}

// .menu-button--delete:hover {
//   color: var(--color-red);
// }

// svg:first-of-type {
//   stroke: var(--color-red);
// }

// .menu-button--orange svg:first-of-type {
//   stroke: var(--color-orange);
// }

// .menu-button--green svg:first-of-type {
//   stroke: var(--color-green);
// }

// .menu-button--purple svg:first-of-type {
//   stroke: var(--color-purple);
// }

// .menu-button--blacksvg:first-of-type {
//   stroke: var(--color-black);
// }

// .menu-button--checked svg:nth-of-type(2) {
//   stroke: var(--color-purple);
// }

.menu-button svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  stroke: var(--color-text-primary-offset);
}

.menu-button:nth-of-type(2) {
  margin-right: 0;
  position: absolute;
  right: 8px;
}

.containerx {
  position: absolute;
  width: 165px;
  border: 1px solid rgb(236, 227, 227);
  border-radius: 5px;
  background: var(--white);
  align-items: center;
  justify-content: center;
  z-index: 1;
}

ul {
  list-style: none;
}

/* ................................................. */

/* MONITORING VISIT........................................... */

.addvisitbtn {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 2px;
  margin-right: 10px;
  width: 110px;
}

.monitoringtbrow {
  height: 105px;
  border-bottom: 2px solid #bfc3c7;
}

.monitoringtbhead {
  border-right: 2px solid #bfc3c7;
  font-weight: lighter;
}

.saturday {
  font-weight: lighter;
}

.monitormain {
  margin-top: 2px;
  height: 105vh;
}

.mx01 {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  color: var(--white);
  padding: 5px;
  margin-top: 3px;
}

/* ........................................................... */

.adverseeventrightbottom {
  width: 100%;
}

/* SITE STATUS.................................. */

.sitestatus-dashboard-body {
  overflow: hidden;
}

.table-responsive-sitestatus {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.sitestatustable {
  width: 200%;
}

.sitestatusmain {
  margin-top: 2px;
}

@media screen and (max-device-width: 1574px) {
  .sitestatustable {
    width: 200%;
  }
}

@media screen and (max-device-width: 1301px) {
  .sitestatustable {
    width: 250%;
  }
}

@media screen and (max-device-width: 1043px) {
  .sitestatustable {
    width: 280%;
  }
}

@media screen and (max-device-width: 932px) {
  .sitestatustable {
    width: 310%;
  }
}

@media screen and (max-device-width: 843px) {
  .sitestatustable {
    width: 350%;
  }
}

@media screen and (max-device-width: 748px) {
  .sitestatustable {
    width: 400%;
  }
}

@media screen and (max-device-width: 656px) {
  .sitestatustable {
    width: 450%;
  }
}

@media screen and (max-device-width: 627px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .sitestatustable {
    width: 450%;
  }

  .table-responsive-sitestatus {
    height: 65vh;
    overflow: auto;
  }
}

@media screen and (max-device-width: 584px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .sitestatustable {
    width: 500%;
  }
}

@media screen and (max-device-width: 527px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .sitestatustable {
    width: 550%;
  }
}

@media screen and (max-device-width: 480px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .sitestatustable {
    width: 600%;
  }
}

@media screen and (max-device-width: 441px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .sitestatustable {
    width: 800%;
  }
}

@media screen and (max-device-width: 333px) {
  .table-responsive-sitestatus {
    height: 60vh;
    overflow: auto;
  }
}

/* ............................................. */

/* SUBJECT STATUS.................................. */

.subjectstatustable {
  width: 250%;
}

.subjectstatusmain {
  margin-top: 2px;
}

@media screen and (max-device-width: 1416px) {
  .subjectstatustable {
    width: 280%;
  }
}

@media screen and (max-device-width: 1265px) {
  .subjectstatustable {
    width: 310%;
  }
}

@media screen and (max-device-width: 1144px) {
  .subjectstatustable {
    width: 350%;
  }
}

@media screen and (max-device-width: 1014px) {
  .subjectstatustable {
    width: 380%;
  }
}

@media screen and (max-device-width: 935px) {
  .subjectstatustable {
    width: 450%;
  }
}

@media screen and (max-device-width: 791px) {
  .subjectstatustable {
    width: 500%;
  }
}

@media screen and (max-device-width: 713px) {
  .subjectstatustable {
    width: 560%;
  }
}

@media screen and (max-device-width: 638px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .subjectstatustable {
    width: 620%;
  }
}

@media screen and (max-device-width: 577px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .subjectstatustable {
    width: 670%;
  }
}

@media screen and (max-device-width: 535px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .subjectstatustable {
    width: 850%;
  }
}

@media screen and (max-device-width: 424px) {
  .subjectstatusmain {
    margin-top: 6px;
  }

  .subjectstatustable {
    width: 970%;
  }
}

@media screen and (max-device-width: 373px) {
  .subjectstatusmain {
    margin-top: 6px;
  }

  .subjectstatustable {
    width: 1200%;
  }
}

/* ............................................. */

/* SUBJECT FROM STATUS.................................. */

.SubjectFormStatustable {
  width: 165%;
}

.SubjectFormStatusmain {
  margin-top: 2px;
}

@media screen and (max-device-width: 1574px) {
  .SubjectFormStatustable {
    width: 200%;
  }
}

@media screen and (max-device-width: 1301px) {
  .SubjectFormStatustable {
    width: 250%;
  }
}

@media screen and (max-device-width: 1043px) {
  .SubjectFormStatustable {
    width: 280%;
  }
}

@media screen and (max-device-width: 932px) {
  .SubjectFormStatustable {
    width: 310%;
  }
}

@media screen and (max-device-width: 843px) {
  .SubjectFormStatustable {
    width: 350%;
  }
}

@media screen and (max-device-width: 748px) {
  .SubjectFormStatustable {
    width: 400%;
  }
}

@media screen and (max-device-width: 656px) {
  .SubjectFormStatustable {
    width: 450%;
  }
}

@media screen and (max-device-width: 627px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .SubjectFormStatustable {
    width: 450%;
  }
}

@media screen and (max-device-width: 584px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .SubjectFormStatustable {
    width: 500%;
  }
}

@media screen and (max-device-width: 527px) {
  .sitestatusmain {
    margin-top: 6px;
  }

  .SubjectFormStatustable {
    width: 550%;
  }
}

@media screen and (max-device-width: 480px) {
  .SubjectFormStatusmain {
    margin-top: 6px;
  }

  .SubjectFormStatustable {
    width: 600%;
  }
}

@media screen and (max-device-width: 441px) {
  .SubjectFormStatusmain {
    margin-top: 6px;
  }

  .SubjectFormStatustable {
    width: 800%;
  }
}

/* ............................................. */

/* COMPARE DATA................................ */

.comparedataleft {
  width: 35%;
  height: 100vh;
}

.table-responsive-comparerighttop {
  max-height: 35vh;
  overflow: auto;
  position: relative;
}

.table-responsive-comparerightbottom {
  max-height: 35vh;
  overflow: auto;
  margin-top: 4px;
  position: relative;
}

.comparedataright {
  width: 65%;
  height: 100vh;
}

.uploadsourceinput {
  width: 500px;
  background-color: var(--blue);
}

.compareupload {
  width: 95%;
  margin: auto;
}

.comparelabel {
  width: 50%;
}

.compareuploadchoose {
  width: 100%;
}

.compareinput {
  width: 50%;
}

.comparebutton {
  padding-top: 280px;
}

.wtogalax {
  background-color: hsl(0deg 0% 95%);
  text-align: center;
}

.loggin-bottom {
  background-color: hsl(0deg 0% 95%);
  padding-top: 10px;
  padding-bottom: 30px;
}

.login-historyhr {
  padding: 1px;
}

.select-application-button {
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 4px;
}

.select-application-label {
  padding-top: 6px;
}

.loginbutton {
  background-color: var(--blue);
  color: var(--white);
  margin-right: 26px;
  width: 100px;
  height: 32px;
  padding: 0px;
}

.welcome-to-galax {
  margin: 0px;
}

.login-history {
  padding-top: 5px;
  padding-bottom: 5px;
}

.login-selectbox,
.login-innerselectbox {
  padding-top: 5px;
  padding-bottom: 5px;
}

.login-history-label {
  width: 7%;
}

.login-history-select {
  width: 30%;
  border: 1px solid var(--blue);
}

.login-text {
  width: 62%;
  margin-left: 15px;
  border: 1px solid var(--blue);
}

@media screen and (max-device-width: 1250px) {
  .login-history-label {
    width: 8%;
  }

  .login-history-select {
    width: 29%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 61.5%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 1100px) {
  .login-history-label {
    width: 9%;
  }

  .login-history-select {
    width: 28%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 61.5%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 990px) {
  .login-history-label {
    width: 10%;
  }

  .login-history-select {
    width: 27%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 61.5%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 870px) {
  .login-history-label {
    width: 11%;
  }

  .login-history-select {
    width: 26%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 61.5%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 780px) {
  .login-history-label {
    width: 14%;
  }

  .login-history-select {
    width: 30%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 54%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 630px) {
  .login-history-label {
    width: 17%;
  }

  .login-history-select {
    width: 33%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 47%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 588px) {
  .loggin-bottom {
    flex-wrap: wrap;
  }

  .login-button {
    width: 500px;
  }
}

@media screen and (max-device-width: 520px) {
  .login-history-label {
    width: 22%;
  }

  .login-history-select {
    width: 36%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 39%;
    margin-left: 15px;
  }
}

@media screen and (max-device-width: 462px) {
  .login-bottoms {
    flex-wrap: wrap;
  }

  .select-app {
    justify-content: center;
  }
}

@media screen and (max-device-width: 425px) {

  .login-innerselectbox,
  .login-history {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .login-history-label {
    width: 100%;
  }

  .login-history-select {
    width: 100%;
    border: 1px solid var(--blue);
  }

  .login-text {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}

/* .......................................... */

/* Sr. BD EXECUTIVE................................. */

@media screen and (max-device-width: 1121px) {
  .sbdeassignstudytable {
    width: 130%;
  }
}

@media screen and (max-device-width: 865px) {
  .sbdeassignstudytable {
    width: 150%;
  }
}

@media screen and (max-device-width: 751px) {
  .sbdeassignstudytable {
    width: 190%;
  }
}

@media screen and (max-device-width: 595px) {
  .sbdeassignstudytable {
    width: 240%;
  }
}

@media screen and (max-device-width: 474px) {
  .sbdeassignstudytable {
    width: 290%;
  }
}

@media screen and (max-device-width: 394px) {
  .sbdeassignstudytable {
    width: 350%;
  }
}

@media screen and (max-device-width: 328px) {
  .sbdeassignstudytable {
    width: 400%;
  }
}

/* .......................................... */

/* DBD PROTOCOL .............................. */

.dbdprotocol {
  height: 65vh;
  margin-top: -5px;
}

.dbprotocoltable {
  overflow: scroll;
}

@media screen and (max-width: 706px) {
  .dbprotocoltable {
    width: 130%;
  }

  .assignstudy {
    margin-top: 5px;
  }
}

@media screen and (max-width: 545px) {
  .dbprotocoltable {
    width: 150%;
  }
}

@media screen and (max-width: 474px) {
  .dbprotocoltable {
    width: 170%;
  }
}

@media screen and (max-width: 421px) {
  .dbprotocoltable {
    width: 210%;
  }
}

@media screen and (max-width: 343px) {
  .dbprotocoltable {
    width: 260%;
  }
}

/* ........................................... */

/* PROTOCOL METADATA.......................... */

.table-responsive-protocol {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

@media screen and (max-width: 706px) {
  .protocoltable {
    width: 130%;
  }

  .assignstudy {
    margin-top: 5px;
  }
}

@media screen and (max-width: 545px) {
  .protocoltable {
    width: 150%;
  }
}

@media screen and (max-width: 474px) {
  .protocoltable {
    width: 170%;
  }
}

@media screen and (max-width: 412px) {
  .protocoltable {
    width: 210%;
  }
}

@media screen and (max-width: 337px) {
  .protocoltable {
    width: 260%;
  }
}

/* ........................................... */

/* DBD LABPARAMETER........................... */

.labparameters {
  margin-top: 2px;
}

/* ........................................... */
/* PROGRAMMING EDITOR.............................. */

.ones {
  width: 48px;
  border-right: 1px solid #e5e5e5 !important;
}

.table-responsive-program {
  height: 40vh;
  overflow: auto;
  position: relative;
}

.programright {
  height: 42vh;
}

.programbottom {
  height: 20vh;
  overflow: auto;
}

.programtab {
  padding-top: 2px;
  padding-bottom: 2px;
}

.program_custom_nav {
  border-bottom: 1px solid #e5e5e5 !important;
}

/* ....................................... */

/* DATA TABLE VIEW............................ */

.datatableviewmain {
  margin-top: 2px;
}

@media screen and (max-width: 1365px) {
  .datatableviewtable {
    width: 150%;
  }
}

@media screen and (max-width: 919px) {
  .datatableviewtable {
    width: 180%;
  }
}

@media screen and (max-width: 768px) {
  .datatableviewtable {
    width: 250%;
  }
}

@media screen and (max-width: 560px) {
  .datatableviewtable {
    width: 300%;
  }
}

@media screen and (max-width: 473px) {
  .datatableviewtable {
    width: 400%;
  }
}

@media screen and (max-width: 360px) {
  .datatableviewtable {
    width: 500%;
  }
}

/* ....................................... */

/* ACCESS MANAGEMENT....................... */

.accessmain {
  display: flex;
  gap: 5px;
}

.accessleft {
  width: 40%;
  height: 100vh;
}

.accessright {
  width: 59.7%;
  height: 100vh;
  border-left: 1px solid #e5e5e5 !important;
  margin-left: 5px;
}

.table-responsive-accessright {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

.accesslabel {
  font-weight: lighter;
  font-size: small;
}

.table-responsive-accessleft {
  max-height: calc(100vh - 182px);
  overflow: auto;
  position: relative;
}

/* ....................................... */

.bold {
  font-weight: 600;
}

/* ....................................... */

.labelTitle {
  font-size: 0.8rem;
}

// ICONS STYLES...............................................................

// ROTATE CHEVRON

.fa-solid,
.fa-regular,
.CP,
.textUser,
.fa-folder,
.fa-home-alt,
.sort {
  cursor: pointer;
}

.CD {
  cursor: default !important;
}

.move {
  cursor: move !important;
}

.rotateChevron {
  transform: rotate(270deg);
}

.green {
  color: #40c057;
}

.dropdown-item.active {
  background: #165483;
}

.dropdown-item:active {
  background: transparent;
  color: #165483;
}

// VALIDATION STAR

.requiredStar {
  fill: #ff0000;
  font-size: 7px;
  margin-bottom: 10px;
}

// HOMELINE ICONS STYLES...........................

.homeLineIcon {
  color: var(--blue);
  cursor: pointer;
}

// ................................................

.fiftyVh {
  height: 25vh;
}

.modal-form-section {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}

.form-button {
  display: flex;
  justify-content: flex-end;
}

.modal-form-body {
  overflow: hidden !important;
}

.profileImage {
  object-fit: cover;
}

.showEntriesData {
  max-height: 44vh;
  overflow: auto;
}

.tableRightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 182px);
}

.crfHight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 191px);
}

.transferrightThree {
  width: 150%;
}

.modalBackground {
  background: rgba(189, 189, 189, 0.9);
}

.backg {
  background: lightblue !important;
}

.subjectstatustable1 {
  width: 100%;
  text-wrap: nowrap;
}

.sitestatustable1 {
  width: 100%;
  text-wrap: nowrap;
}

.queryField_input_size {
  width: 607px !important;
}

.wide {
  width: 100% !important;
}

.mid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--checked {
  background-color: transparent;
  color: var(--blue);
  border: 1px solid var(--blue) !important;
}

.data-item {
  width: 33.33%;
  padding: 10px;
  box-sizing: border-box;
}

.datSetHead {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}

.optionValue {
  margin-left: 10px;
}

nav.react-contextmenu {
  min-width: 180px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.74);
  z-index: 999;
  color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  overflow: hidden;
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #000;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 13px;
  display: block;
  text-decoration: none;
  padding: 5px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
  color: #000;
}

.react-contextmenu-item span {
  margin-left: 10px;
  color: #fff;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .edit {
  color: rgb(80, 193, 80);
}

.react-contextmenu-item .lock {
  color: gray;
}

.react-contextmenu-item .delete {
  color: #e94e51;
}

.react-contextmenu-item .history {
  color: rgb(80, 193, 80);
}

.dropend.btn-group button {
  background: none;
  border: none;
  font-size: 13px;
  color: #000;
  margin: -10px 0;
  width: 196px;
  text-align: left;
}

.dropend.btn-group button {
  color: #000;
}

.capitalize {
  text-transform: capitalize !important;
}

.dropend>div {
  border: none;
  transform: translate3d(190px, -8px, 0px) !important;
  background-color: rgb(0 0 0 / 74%);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  .dropdown-item {
    padding: 5px;

    &:hover {
      background: #000;
    }
  }
}

.dropend .dropdown-toggle::after {
  position: absolute;
  right: 0;
  top: 10px;
}

.has-search {
  position: relative;

  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 4.375rem;
    height: 35px;
    line-height: 2rem;
    text-align: center;
    pointer-events: none;
    color: var(--white);
    right: 0;
    margin: 0px 1px;
    background: #103c5e;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
  }

  span.activeClientSign {
    width: 10px;
    height: 10px;
    background: green;
    position: absolute;
    border-radius: 50%;
    top: 10px;
  }

  .InactiveClientSign {
    width: 10px;
    height: 10px;
    background: red;
    position: absolute;
    border-radius: 50%;
    top: 10px;
  }

  .FrozenClientSign {
    width: 10px;
    height: 10px;
    background: #ddaf00;
    position: absolute;
    border-radius: 50%;
    top: 10px;
  }

  .OnBoardingClientSign {
    width: 10px;
    height: 10px;
    background: blue;
    position: absolute;
    border-radius: 50%;
    top: 10px;
  }

  .ArchivedClientSign {
    width: 10px;
    height: 10px;
    background: gray;
    position: absolute;
    border-radius: 50%;
    top: 10px;
  }
}

.toolbox_section {
  border-left: 1px solid #e5e5e5;
}

.tab_header {
  .tabbing_heading {
    margin: 0;
  }
}

.ql-snow .ql-picker {
  height: auto !important;
}

.ql-toolbar.ql-snow {
  padding: 0 !important;
  background-color: var(--bs-border-color);
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
  padding: 5px;
  border-right: 1px solid rgb(204 204 204) !important;
}

.ql-snow.ql-toolbar button,  
.ql-snow .ql-toolbar button,
.ql-snow .ql-picker{
  border-radius: 0;
}

.ql-snow.ql-toolbar button,  
.ql-snow .ql-toolbar button{
  width: 50px !important;
  display: flex !important;
  margin: 0 auto;
  justify-content: center;
}

#queryWrapper> :last-child {
  display: none;
}

#queryWrapper.switched> :last-child {
  display: block;
}

#queryWrapper.switched> :first-child {
  display: none;
}

.outputSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.outputSection p {
  border: 1px solid #e5e5e5;
  padding: 2px 20px;
  margin: 5px 0 5px 10px;
  font-size: 12px;
}

.queryResponse {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 50px;
}

.add_puls {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

span.CP.ps-3.small,
span.CP.ps-4.small {
  padding: 6px 10px;
  display: flex;
  width: 100%;
  align-items: center;
  text-wrap: nowrap;
  overflow: hidden;
  font-size: 12px;
  border-bottom: 1px solid #e5e5e5;

  &:hover {
    color: var(--blue);
    background: #103c5e57;
  }

  &.active {
    background: #103c5e57;
  }
}

.selectedMenu {
  color: var(--blue);
  background: #103c5e57;
}

.querymodel button {
  height: 20px;
}

.validInfo h1 {
  font-size: 15px;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  color: #00000078;
}

.validInfo p {
  font-size: 12px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.4705882353);
  padding-right: 15px;
  height: 30vh;
  overflow: auto;
  margin: 0;
}

.highlighted {
  background-color: #ccd6dd !important;
}

a.mt-1.small {
  padding: 2px;
  transition: 1s;

  &:hover {
    background: #e3e0d4;
    border-radius: 5px;
  }

  // &.active {
  //   background: #e3e0d4;
  //   border-radius: 5px;
  // }
}

.manag_section {
  overflow: auto;
  height: calc(100vh - 215px);
  border-left: 1px solid #e5e5e5;

  .Management_section {
    p {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

.custom-checkbox .form-check-input {
  transform: scale(0.8);
  margin-right: 10px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
}

#react-select-7-placeholder {
  font-size: 13px;
  font-weight: 300;
}

#react-select-6-placeholder {
  font-size: 13px;
  font-weight: 300;
}

#react-select-5-placeholder {
  font-size: 13px;
  font-weight: 300;
}

#react-select-4-placeholder {
  font-size: 13px;
  font-weight: 300;
}

#react-select-3-placeholder {
  font-size: 13px;
  font-weight: 300;
}

#react-select-2-placeholder {
  font-size: 13px;
  font-weight: 300;
}

.css-1dimb5e-singleValue {
  font-size: 13px;
  font-weight: 300;
}

.css-qbdosj-Input {
  margin: 0 !important;
}

.radio_check input {
  margin: 0 10px 0 0;
}

.all_promices {
  border: 1px dashed #e9ecef;
  padding: 10px 5px;
}

.query_statuis_code {
  background: #e3e0d4;
  margin: 10px;
  padding: 11px;
  border-radius: 10px;
  font-size: 12px;
}

.query_statuis_code .query {
  color: #843c0c;
}

.query_statuis_code .code {
  color: #1b659d;
}

.query_statuis_code .datetime {
  color: #c55a11;
}

.View_Full_Chart {
  background: #ee9e23;
  padding: 0 10px;
  border-radius: 3px;
  color: var(--white);
  font-weight: 700;
  cursor: pointer;
}

.Open__query__icon {
  background: rgb(0, 143, 251) !important;
  color: rgb(0, 143, 251);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 2px;
  display: inline-block;
}

.Answered__query__icon {
  background: rgb(0, 227, 150) !important;
  color: rgb(0, 227, 150);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 2px;
  display: inline-block;
}

.Cancelled__query__icon {
  background: rgb(254, 176, 25) !important;
  color: rgb(254, 176, 25);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 2px;
  display: inline-block;
}

.Closed__query__icon {
  background: rgb(255, 69, 96) !important;
  color: rgb(255, 69, 96);
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 2px;
  display: inline-block;
}

foreignObject {
  position: relative;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
  margin-top: 0;
}

.query_statuis_code_wrap {
  height: calc(100vh - 323px);
  overflow: auto;
}

.Assign_Permission_button {
  position: absolute;
  bottom: 174px;
  width: 100%;
  padding: 5px;
  background: var(--white);
}

.list_of_Role {
  height: calc(100vh - 230px);
  overflow: auto;
}

.all___buton_style {
  .freeze {
    background: gray;
    font-size: 10px;
    border-radius: 4px;
    border: none;
    color: var(--white);
    padding: 3px 10px;
    width: 75px;
    margin: 5px 10px 0;
  }

  .unfreeze {
    background: green;
    font-size: 10px;
    border-radius: 4px;
    border: none;
    color: var(--white);
    padding: 3px 10px;
    width: 75px;
    margin: 5px 10px 0;
  }

  .lock {
    background: red;
    font-size: 10px;
    border-radius: 4px;
    border: none;
    color: var(--white);
    padding: 3px 10px;
    width: 75px;
    margin: 5px 10px 0;
  }

  .unlock {
    background: green;
    font-size: 10px;
    border-radius: 4px;
    border: none;
    color: var(--white);
    padding: 3px 10px;
    width: 75px;
    margin: 5px 10px 0;
  }
}

button.saveScreen {
  background: #103c5e;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px 12px;
  color: var(--white);
}

.remark__modal textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #e6e5e5;
  border-radius: 4px;
  resize: none;
  font-size: 11px;
  padding: 5px;
}

div#chart {
  // background: #cacaca3d;
  padding: 15px;
  position: relative;
  // width: 90%;
}

.center__data__chart {
  margin: 0 auto;
  position: absolute;
  top: 55%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.table-responsive-dashboard {
  overflow-x: hidden;
  height: calc(100vh - 143px);
}

.user__info {
  background: #103c5e;
  color: var(--white);
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.activity__section {
  background: #80808024;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  gap: 20px;
}

.activity__portal {
  height: calc(100vh - 342px);
  overflow: auto;

  p {
    font-size: 12px;
  }
}

.leftHeightOfSection {
  height: calc(100vh - 270px);
  overflow: auto;
  border-right: 1px solid rgba(128, 128, 128, 0.1411764706);
}

.activity__portal-study {
  height: calc(100vh - 231px);
  overflow: auto;

  p {
    font-size: 12px;
  }
}

.chart__overview {
  h4 {
    font-size: 13px;
  }
}

.doughtnutClass {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  background: #e3e6ed;

  .chart_yaxis_legend {
    position: relative;

    p {
      transform: rotate(-90deg);
      text-wrap: nowrap;
      margin: 25px;
      margin-top: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .chart_xaxis_legend {
    p {
      text-align: center;
    }
  }
}

.substatus {
  text-align: right;
}

.substatus p {
  font-size: 13px;
  margin: 0;
}

.substatus p i[data-tooltip]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  background-color: #333;
  color: var(--white);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 1;
}

.substatus p i[data-tooltip]:hover::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent #333 transparent;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.substatus p i[data-tooltip]:hover::before,
.substatus p i[data-tooltip]:hover::after {
  opacity: 1;
}

.addUnit__source {
  padding: 5px;
  font-size: 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: 1s;
}

svg.addUnit__source:hover {
  background: gray;
  color: var(--white);
}

.dbp__mode__color {
  color: #d1a213;
}

.qc__mode__color {
  color: #d82d2d;
}

.live__mode__color {
  color: #40c057;
  font-size: 12px;
}

.error__log span {
  font-size: 12px;
  color: red;
}

.selectrowcolor {
  background: rgb(204, 214, 221);
  cursor: pointer;
}

.withoutcolorrow {
  cursor: pointer;
}

.cdashShortleft {
  width: 40%;
  height: 100vh;
  border-right: 1px solid #e5e5e5 !important;
}

.table-responsive-cdashLongleft {
  overflow-y: auto;
  position: relative;
  // padding: 0 12px;
}

span.keyButton {
  padding: 8px 15px;
  font-size: 12px;
  font-weight: bold;
  margin: 4px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  border-radius: 4px;
}

span.keyButton:hover {
  background: #00000024;
  cursor: pointer;
}

.keyboard {
  display: flex;
  justify-content: center;
}

.textKeyButton {
  font-size: 12px;
}

.enterKeyButton {
  padding: 8px;
  margin: 4px;
  border: 1px solid #103c5e;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  color: var(--white);
  width: 25%;
  background-color: #103c5e;
  cursor: pointer;
}

.enterKeyButton:hover {
  border: 1px solid #103c5e;
  color: #103c5e;
  background-color: var(--white);
  transition: 0.5s;
  font-weight: 600;
}

.cleanKeyButton {
  padding: 8px;
  margin: 4px;
  border: 1px solid #103c5e;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  width: 25%;
  color: #103c5e;
  background-color: var(--white);
  cursor: pointer;
}

.cleanKeyButton:hover {
  border: 1px solid #103c5e;
  color: var(--white);
  background-color: #103c5e;
  transition: 0.5s;
  font-weight: 600;
}

.keyBoardButtonTitle {
  cursor: pointer;
  border: 1px solid #103c5e;
  padding: 3px 18px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
  color: #103c5e;
  transition: 1s;

  &:hover {
    background-color: #103c5e;
    color: var(--white);
    border: 1px solid #103c5e;
  }
}

.keyBoardButtonTitleActive {
  background-color: #103c5e;
  color: var(--white);
  border: 1px solid #103c5e;
}

.letDataClass {
  font-size: 14px;
  margin-left: 60px;
}

button.clearButton {
  background-color: #103c5e;
  color: var(--white);
  border-radius: 3px;
  font-size: 12px;
  padding: 2px 15px;
  margin-top: 12px;
}

button.clearButton:hover {
  transition: 0.3s;
  background-color: var(--white) fff;
  color: #103c5e;
  border: 1px solid #103c5e;
  font-weight: 600;
}

.accessTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
}

.environmentinput {
  border: 1px solid #cacccd;
  width: 100%;
  height: 2.3rem;
}

.details_of_forms_sign {
  h4 {
    font-size: 17px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
  }

  img {
    width: 100px;
  }

  span {
    color: red;
  }

  .date__box__ {
    border: 1px solid #e5e5e5;
    padding: 7px;
    border-radius: 4px;
    margin: 0;
  }
}

.Header_Title {
  p {
    font-size: 12px;
  }

  img {
    width: 100px;
  }
}

.report_form {
  height: calc(100vh - 190px);
  overflow: auto;
  width: 65%;
  margin: 5px auto;
}

.sample_report_form {
  .report_form_heading {
    h6 {
      background: #eee;
      padding: 10px 0;
      text-align: center;
      font-weight: 600;
    }

    p {
      text-align: center;
      font-size: 12px;
    }
  }
}

.box__sign_ {
  border: 2px solid #e5e5e5;
  border-radius: 10px;
  padding: 20px 20px 10px;
  margin-bottom: 10px;
}

.formate_input {
  input {
    width: 30px;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    height: 32px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
  }

  .form-select {
    font-size: 12px;
    border-radius: 0;
  }
}

.dd_formate {
  input {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100px;
    border: 1px solid #e5e5e5;
    height: 32px;
    font-size: 12px;
  }
}

.handle___print button {
  position: absolute;
  bottom: -50px;
  left: 6px;
}

@media print {
  .report_form_heading {
    break-inside: avoid-page;
  }

  .subject__details_need p {
    white-space: pre-wrap !important;
  }

  div#chart {
    width: 30% !important;
  }

  .study_overview__rightsection,
  .subject__details_need,
  .titmeline__border .timeline__section {
    height: auto !important;
  }
}

button.submitbutton.print___extract_button {
  position: absolute;
  bottom: -51px;
  right: 40px;
}

.button____group {
  button {
    background: none;
    border: none;
    text-align: left;
    transition: 1s;

    &:hover {
      background: #6c757d5e;
      border-radius: 4px;
    }
  }

  .activeChartType {
    background: #6c757d5e;
    border-radius: 4px;
  }
}

.image_chart_image {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;

  img {
    width: 60px;
  }
}

.inputText__Chart {
  padding: 10px;
}

button.button_group_year {
  margin: 0;
  background: var(--white);
  color: #000;
  border-color: #e5e5e5;
  font-size: 14px;
  padding: 7px;
}

.graph__title__border {
  border-bottom: 1px solid rgb(229, 229, 229);
  background: #80808033;
  padding: 10px;
}

.btn:focus-visible {
  box-shadow: none !important;
}

button.button_group_year_active {
  background-color: #103c5e;
  color: var(--white);
}

/* LineNumberTextarea.css */

.editor {
  display: flex;
}

.line-numbers {
  width: 40px;
  background-color: #e5e5e5;
  padding: 4px;
  color: #103c5e;
  font-family: monospace;
  font-weight: 600;
}

.codeEditor {
  flex: 1;
  font-size: 14px;
  line-height: 1.4;
  border: 1px solid #ccc;
  resize: vertical;
  overflow: hidden;
  min-height: 243px;
}

:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.tab_pane_height {
  height: calc(100vh - 508px);
  overflow: auto;
}

.tab_pane_height>div {
  cursor: pointer;
}

.tab_pane_height>div:hover {
  background: #efefef;
}

.reset_password__sections {
  width: 60%;
  margin: 0 auto;
  border: 1px solid #1f537a;
  border-radius: 10px;
}

.disablebutton {
  background: #918f8f !important;
  color: var(--white) !important;
  border: 1px solid transparent !important;
}

.chart_design_port {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 320px;
}

.title_bar_items {
  // background-color: hsl(0, 0%, 94%);
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  position: relative;

  h6 {
    font-size: 13px;
    margin: 0;
    font-weight: 600;
  }
}

.title_bar_scroll_height {
  height: calc(100vh - 250px);
  overflow-x: hidden;
  width: 350px;
}

.title__bar_content {
  padding: 10px;
  border-radius: 4px;
  width: 328px;
  position: relative;

  .study__icon {
    i {
      font-size: 7px;
      margin-right: 4px;
    }
  }
}

.dropdown-menu.show {
  display: block;
  font-size: 11px;
  padding: 0;
}

.title__bar_value1 {
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
  padding: 6px 10px;
}

.title__bar_value,
.title__bar_values {
  background: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
  padding: 6px 10px;
}

.title__bar_value:hover,
.title__bar_value_active:hover {
  background: #ccc;
}

.title__bar_value_active {
  background: #ccc !important;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: small;
  padding: 6px 10px;
}

.Chart1>div,
.Chart2>div,
.Chart3>div {
  width: 39px;
}

.graph_position {
  margin-right: 30px;
}

// .Chart1{
//   position: absolute;
//   top: 0px;
//   right: 0;
// }

// .Chart2 {
//   position: absolute;
//   top: 0;
//   right: 30px;
// }

// .Chart3{
//   position: absolute;
//   top: 0;
//   right: 60px;
// }

.threedots:after {
  content: "\2807";
  font-size: 16px;
}

.dropdown a {
  text-decoration: none;
  color: #262626;
  font-size: 13px;
}

// .dropdown a:hover {
//   background-color: grey;
//   padding: 3px 3px 2px;
//   border-radius: 3px;
// }

.threedots:hover {
  background-color: grey;
  padding: 4px 2px 2px 6px;
  border-radius: 4px;
}

.dropdown_menus_tableview {
  min-width: 180px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.74);
  z-index: 999;
  color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  overflow: hidden;
  line-height: 24px;

  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.74) !important;
  }
}

.data__justification {
  display: flex;
  overflow: auto;
}

.subject__idenetity i {
  font-size: 20px;
}

.subject__idenetity img {
  width: 20px;
}

.css-1jqq78o-placeholder {
  font-size: 12px;
}

.subject__details_need {
  height: calc(100vh - 340px);
  overflow: auto;

  p {
    line-height: 30px;
    white-space: nowrap;
  }
}

.icon__bg {
  display: grid;
  gap: 2px;
}

.icon__bg i {
  background: #f0f0f0;
  padding: 4px;
  border-radius: 4px;
  font-size: 10px;
}

label.form-check-label {
  font-size: 12px;
  margin-top: 4px;
}

.form-check {
  display: flex;
  align-items: center;
}

.screening_right_panel {
  height: calc(100vh - 165px);
  overflow: auto;

  p {
    font-size: small;
    font-weight: bold;
  }

  label {
    font-size: small;
  }

  input {
    font-size: small;
  }

  select {
    font-size: small;
  }
}

.overview_left_section {
  img {
    width: 100px;
  }

  span {
    margin-bottom: 10px;
    padding: 4px 32px;
    text-align: center;
  }

  p {
    font-size: 12px;
    margin: 0;
  }
}

.titmeline__border {
  border-left: 1px solid #efefef;

  .timeline__section {
    background: #e0e0e0;
    height: calc(100vh - 170px);
    overflow: auto;
  }
}

.timeline {
  padding: 10px;

  .tl-content .tl-header {
    padding-left: 25.6px;
    border-left: 2px solid rgb(190, 172, 11);
    height: 80px;
    align-items: center;
  }

  .tl-contents .tl-header {
    padding-right: 25.6px;
    border-right: 2px solid rgb(190, 172, 11);
    text-align: end;
    align-items: center;
    height: 80px;
  }

  .tl-header {
    position: relative;
    display: grid;
    padding-top: 1rem;
    padding-bottom: 1rem;

    p {
      margin: 0;
    }

    .tl-title {
      font-size: 12px;
      font-weight: 400;
    }

    .tl-titles {
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
    }

    .tl-marker {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50% / 50%;
      background: rgb(7, 230, 22);
      left: -15px;
      top: 50%;
      transform: translate(50%, -50%);
    }

    .tl-marker-gray {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50% / 50%;
      background: rgb(126, 131, 127);
      left: -15px;
      top: 50%;
      transform: translate(50%, -50%);

      i {
        font-size: 11px;
        top: 2px;
        position: absolute;
        left: 2.5px;
      }
    }

    .tl-marker-red {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50%/50%;
      background: rgb(245, 6, 6);
      left: -15px;
      top: 50%;
      transform: translate(50%, -50%);

      i {
        font-size: 11px;
        top: 2px;
        position: absolute;
        left: 4px;
        color: var(--white);
      }
    }

    .tl-markers {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50% / 50%;
      background: rgb(7, 230, 22);
      right: -0.9px;
      top: 50%;
      transform: translate(50%, -50%);
    }

    .tl-markers-gray {
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 50% / 50%;
      background: rgb(126, 131, 127);
      right: -0.9px;
      top: 50%;
      transform: translate(50%, -50%);
    }
  }
}

.study_overview__rightsection {
  height: calc(100vh - 158px);
  overflow-x: hidden;
}

.forn_value__section {
  p {
    margin: 0;
    font-size: 12px;
    padding: 5px;
  }
}

#total-counter {
  counter-reset: totalPages;
}

#total-counter span {
  counter-increment: totalPages;
}

.page-counter:after {
  counter-increment: page;
  content: "Page " counter(page) " / " counter();
}

.graph__title img {
  cursor: pointer;
}

.studyvisitPlanData {
  height: calc(100vh - 143px);
  overflow: auto;
}

.subjectMileStoneData {
  height: calc(100vh - 170px);
  overflow: auto;
}

.border_body_box {
  margin: 10px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 5px;

  p {
    font-size: 12px;
    margin: 0;
  }
}

.Scorll___Query {
  overflow: auto;
  height: 340px;
}

.scroll__site_subject {
  overflow: auto;
  height: 390px;
}

.border__right_query {
  border-right: 1px solid #e5e5e5;
}

button#dropdown-split-basic {
  border: none;
  color: #000;
  padding: 6px 12px 0;
  margin: 0;
}

.noHaveDropdown {
  margin-right: 33px;
}

td.green {
  color: green;
  font-weight: 600 !important;
}

td.red {
  color: red;
  font-weight: 600 !important;
}

.title_bar_items .btn.show:focus,
.title_bar_items .btn:focus,
.nombering__point .btn.show:focus,
.nombering__point .btn:focus {
  box-shadow: none !important;
  background-color: #ccc !important;
}

.nombering__point ul {
  margin: 0;
  padding: 0;
}

.nombering__point ul li {
  padding: 6px 12px;
  font-size: 12px;
  background: #f0f0f0;
  border-radius: 3px;
  margin: 10px 0px 0 5px;
}

.title_bar_items .dropdown-toggle::after,
.nombering__point .dropdown-toggle::after {
  content: "⠇";
  // font-size: 17px;
  border: none !important;
}

.nombering__point {
  margin: 5px 0px 0 5px;
  width: 60px;
}

.backgrundData {
  margin: 0;
  background-color: #e8e5e5 !important;
}

.nombering__point button {
  padding: 0px;
}

.table-responsive-dashboard-study {
  height: calc(100vh - 155px);
  overflow-x: hidden;
}

// Breadcrumb Design CSS ---------------------------

$blue-gray: #34495e;
$blue-gray-darken: #2c3e50;
$blue: #3498db;
$blue-darken: #2980b9;
$green: #1abc9c;
$green-darken: #16a085;

#breadcrumb {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 5px 0px 0px;

  li {
    float: left;

    a {
      color: #fff;
      display: block;
      background: var(--blue);
      text-decoration: none;
      position: relative;
      height: 19.5px;
      line-height: 18px;
      padding: 0 10px 0 5px;
      margin-right: 23px;
      font-size: 12px;
    }

    &:nth-child(even) {
      a {
        background-color: $blue-darken;

        &:before {
          border-color: $blue-darken;
          border-left-color: transparent;
        }

        &:after {
          border-left-color: $blue-darken;
        }
      }
    }

    &:first-child {
      a {
        padding-left: 15px;

        &:before {
          border: none;
        }
      }
    }

    &:last-child {
      a {
        padding-right: 15px;

        &:after {
          border: none;
        }
      }
    }

    a {

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        border: 0 solid $blue;
        border-width: 10px 11px;
        width: 0;
        height: 0;
      }

      &:before {
        left: -20px;
        border-left-color: transparent;
      }

      &:after {
        left: 100%;
        border-color: transparent;
        border-left-color: $blue;
      }

      &:hover {
        background-color: $green;

        &:before {
          border-color: $green;
          border-left-color: transparent;
        }

        &:after {
          border-left-color: $green;
        }
      }

      &:active {
        background-color: $green-darken;

        &:before {
          border-color: $green-darken;
          border-left-color: transparent;
        }

        &:after {
          border-left-color: $green-darken;
        }
      }
    }
  }
}

.twoFactor {
  display: flex;
  justify-content: center;
  font-size: 95px;
  color: limegreen;
}

.twoFATitle {
  justify-content: center;
  display: flex;
  font-weight: 600;
}

.qr__imag {
  display: flex;
  justify-content: center;
}

.study_icopn i {
  background: #fff;
  font-size: 25px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: green;
}

.chart__overview .card {
  border-radius: 4px;

  .card-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .card-text:last-child {
    font-size: 13px;
  }
}

.study_icopn i,
.study_icopn svg {
  background: var(--white);
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue);
}

.assign_icopn span {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: green;
}

.height-home {
  height: calc(100vh - 225px);
  overflow: auto;
}

.progress-bar {
  background-color: #103c5e;
}

td {
  vertical-align: middle;
}

// .chart__overview {
//   .card {
//     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//     border: 0;
//     background: #e3e6ed;

//     .card-title {
//       font-size: 16px;
//       font-weight: 600;
//       margin: 0;
//     }

//     .card-text:last-child {
//       font-size: 13px;
//     }
//   }
// }

/* Profile CSS  */
.Apps {
  .Apps_dd {
    width: 250px;
    position: absolute;
    top: 37px;
    background: #fff;
    z-index: 999;
    border-radius: 5px;

    label {
      color: var(--blue);
      padding: 6px 15px 0;
      border-bottom: 1px solid #80808030;
      width: 100%;
      margin: 0;
      font-size: 13px;
    }

    ul {
      li {
        border-bottom: 1px solid #80808030;

        a {
          display: block;
          padding: 6px 15px;
          position: relative;
          font-weight: 500;
          font-size: 12px;
          color: var(--blue);
          text-decoration: none;

          &:hover {
            color: var(--blue);
            background: #103c5e57;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .Apps_ul {
      padding: 0;
      overflow: hidden;
      margin: 0;
      max-height: 0;
      transition: max-height 0.5s ease-in-out;
    }
  }

  .icon_wrap {
    .title_name {
      font-size: 14px;

      i {
        font-size: 14px;
        margin-top: 1px;
      }
    }
  }
}

.AppsNameActive,
.title_name:hover {
  background: #fff;
  border-radius: 2px;
  color: var(--blue);
  transition: 1s;
}

.Apps.active .Apps_ul {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.link__tab {
  border-bottom: 1px solid var(--bs-border-color);
  transition: 1s;
}

.Apps_dd .border-left {
  border-left: 1px solid #ddd;
  padding-left: 10px;
}

.modalAvatar {
  border: 1px solid #103c5e;
  border-radius: 8px;
  padding: 1px;
  display: flex;
  height: 7rem;
  width: 26%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.link__tab .tab_url {
  font-size: 12px;
  text-decoration: none;
  color: var(--inactive_menu_color);
  padding: 7px 10px;
  white-space: nowrap;
  border-left: 4px solid transparent;
  cursor: pointer;
}

.sidebar__active {
  background: #ccd6dd;
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

.custom-table {

  th,
  td {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    font-size: 11px !important;
    padding: 5px;
  }

  th {
    resize: horizontal;
    background-color: #b8cfdf;
  }
}

.table_final td {
  font-size: 13px;
  padding: 5px;
}

.fixed_tab_button {
  background: var(--white);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--bs-border-color);
}

.resizer-x,
.resizer-y {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: relative;
  transition: 1s;
}

.resizer-y {
  border-bottom: 1px solid var(--bs-border-color);
  border-top: 1px solid var(--bs-border-color);
  cursor: row-resize;
  flex-direction: column;
  z-index: 1;
}

.resizer-y:after,
.resizer-y:before {
  height: 1px;
  width: 16px;
}

.resizer-x:after,
.resizer-x:before,
.resizer-y:after,
.resizer-y:before {
  background: #737373;
  content: "";
  margin: 1px;
}

.custom-table input {
  border: 0;
  outline: none !important;
  padding: 6px;
  // width: 100%;
}

.div0 {
  width: 250px;
}

.resizable-y {
  min-width: 50%;
}

.eventleft {
  width: 27%;
  height: 100vh;
  margin-top: 3px;
}

.eventfilter {
  background-color: transparent;
}

.eventright {
  height: 100vh;
  margin-top: 3px;
}

.question {
  height: 1.5rem;
}

.resizable-y {
  flex: 40%;
}

.buttonForTabs {
  background: #dedede;
  border-radius: 6px 6px 0 0;
  color: #000;
  font-size: 12px;
  min-width: 90px;
  padding: 3px 10px;
  text-align: center;
  transition: 1s;

  &:hover {
    background: #b8cfdf;
    // color: var(--white);
  }

  &.activee {
    background: var(--blue);
    color: var(--white);
  }
}

.toolbar i {

  &.activee,
  &:hover {
    background: var(--blue);
    color: var(--white);
    transition: 1s;
  }
}

.add_sponsor_field .input-group-text {
  font-size: 11px;
  background-color: #dedede;
  padding: 3px 8px 0;
  border-radius: 3px;
  border-color: #dedede;
}

.search_button:hover {
  background-color: rgb(216, 52, 52);
}

.search_button:hover i {
  color: #fff !important;
}

.teams_button:hover {
  background-color: #103C5E;
}

.teams_button:hover i {
  color: #fff !important;
}

.ql-editor{
  height: 200px !important;
  overflow: auto;
}